import { alertDeleteRecord } from "@app/products/direct-debit/constant";
import { DirectDebitStatusCode } from "@app/products/direct-debit/system-admin/model";
import { getDirectDebitStatusCodeById } from "@app/products/direct-debit/system-admin/status-codes/[id]/api";
import { ExistManageDirectDebitStatusCode } from "@app/products/direct-debit/system-admin/status-codes/[id]/existed/_index";
import { NewManageDirectDebitStatusCode } from "@app/products/direct-debit/system-admin/status-codes/[id]/new/_index";
import { checkIsInactiveDirectDebitStatusCode } from "@app/products/direct-debit/system-admin/status-codes/[id]/util";
import { TitleDirectDebitStatusCodeManagePage } from "@app/products/direct-debit/system-admin/status-codes/constant";
import { APIResponse } from "@common/apis/model";
import { useFlexibleFetchData } from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { useIsNew } from "@common/hooks/useIsNew";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

const ManageDirectDebitStatusCode = () => {
  //#region STORE ========/
  const {
    listenNotificationPortal,
    pushNotificationPortal,
    clearNotifications,
  } = useNotificationPortalStore();
  const history = useHistory();
  const { setInitialDataForms, resetDataForm, isLoadingForm } =
    useFlexibleFormStore();
  useEffectOnce(() => {
    return () => {
      resetDataForm();
    };
  });
  //#endregion STORE =====/

  //#region HOOK ========/
  const { cancelToken } = useCancelRequest();
  const isNew = useIsNew();
  //#endregion HOOK =====/

  //#region Variable ========/
  let { id } = useParams<{ id: string }>();
  const recordID = useMemo(() => {
    return isNew ? "0" : id;
  }, [isNew, id]);
  //#endregion Variable =====/

  const manageDirectDebitStatusCodeSlider = useFlexibleFetchData({
    alias: "manageDirectDebitStatusCodeSlider",
    slides: [
      {
        fetch: () => getDirectDebitStatusCodeById(+recordID, cancelToken()),
        handleSuccess: ({ dataFromApi }) => {
          const response: APIResponse<DirectDebitStatusCode> = dataFromApi;
          const ddStatusCodeFormData = response?.data;
          //#region save data to store ========/
          setInitialDataForms({
            GeneralForm: ddStatusCodeFormData,
          });
          //#endregion save data to store =====/

          //#region Notification ========/
          listenNotificationPortal();

          if (checkIsInactiveDirectDebitStatusCode()) {
            pushNotificationPortal(alertDeleteRecord, true);
          }
          //#endregion Notification =====/
        },
      },
    ],
  });

  useEffect(() => {
    manageDirectDebitStatusCodeSlider.fetchApi();
    return () => clearNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location]);

  return (
    <>
      <Loading
        isLoading={
          manageDirectDebitStatusCodeSlider.isFetching || isLoadingForm
        }
        isFullScreen
      />
      <FormNavigation title={TitleDirectDebitStatusCodeManagePage} />
      {manageDirectDebitStatusCodeSlider.errorComponent ? (
        manageDirectDebitStatusCodeSlider.errorComponent
      ) : isNew ? (
        <NewManageDirectDebitStatusCode />
      ) : (
        <ExistManageDirectDebitStatusCode />
      )}
    </>
  );
};

export default observer(ManageDirectDebitStatusCode);
