import { getDDAccountItemTypeLOVs } from "@app/products/direct-debit/accounts/[id]/api";
import { getKeyValueFromLOVs } from "@app/products/direct-debit/accounts/[id]/util";
import {
  ASSESSMENT_LOOKUP_API,
  DD_CREATE_ACCOUNT,
} from "@app/products/direct-debit/accounts/components/dialogs/create-account/constant";
import { IDirectDebitAccountFormStepProps } from "@app/products/direct-debit/accounts/components/dialogs/create-account/steps/general";
import { ComboboxDDSearchAPI } from "@app/products/direct-debit/accounts/components/fields/combobox-search-api";
import {
  DirectDebitAccountLOVs,
  FinanceCustomer,
  IntegrationModuleItemType,
} from "@app/products/direct-debit/accounts/model";
import { IntegrationModuleType } from "@app/products/direct-debit/model";
import { APIResponse } from "@common/apis/model";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import {
  fetchApiByAlias,
  useFlexibleFetchData,
} from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { ResponsePacket } from "@common/models/identityPacket";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCLabel } from "@components/cc-label/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

const nameOf = nameOfFactory<IDirectDebitAccountFormStepProps>();
export const DDAccountDetailsStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = observer(({ formRenderProps }: IFormStepElement) => {
  const { valueGetter, onChange } = formRenderProps;

  const { dataForms, setInitialDataForms } = useFlexibleFormStore();
  const { cancelToken } = useCancelRequest();
  const { pushNotificationPortal } = useNotificationPortalStore();

  const ddAccountsLOVs = dataForms?.GeneralFormLovs as DirectDebitAccountLOVs;

  const getValue = (nameOfField: keyof IDirectDebitAccountFormStepProps) =>
    valueGetter(nameOf(nameOfField));
  const onChangeField = (
    nameOfField: keyof IDirectDebitAccountFormStepProps,
    value: any
  ) => {
    onChange(nameOf(nameOfField as keyof IDirectDebitAccountFormStepProps), {
      value: value,
    });
  };

  const getItemTypeLOVsSlider = useFlexibleFetchData({
    alias: "getItemTypeLOVsSlider",
    slides: [
      {
        fetch: () => {
          return getDDAccountItemTypeLOVs(
            cancelToken(),
            getValue("Integration_Module_Type_ENUM") as IntegrationModuleType
          );
        },
        handleSuccess: ({ dataFromApi }) => {
          const response: APIResponse<IntegrationModuleItemType[]> =
            dataFromApi;
          const ddAccountFormData = response.data;
          //#region save data to store ========/
          setInitialDataForms({
            GeneralFormLovs: {
              ...dataForms?.GeneralFormLovs,
              ItemTypes: getKeyValueFromLOVs(ddAccountFormData),
            },
          });
          //#endregion save data to store =====/
        },
        handleError: () => {
          pushNotificationPortal({
            title: "There is an error when getting Item Types",
            type: "error",
            placeId: DD_CREATE_ACCOUNT,
            autoClose: false,
          });
        },
      },
    ],
  });

  const handleFinanceCustomerChange = (event: ComboBoxChangeEvent) => {
    const financeCustomer = event.target.value as FinanceCustomer;
    onChangeField("Finance_Customer_No", financeCustomer?.CustomerNo);
    onChangeField("_options", {
      ...getValue("_options"),
      Finance_Customer_Display: financeCustomer?.SearchText,
    });
  };

  const handlePropertyLookupChange = (event: ComboBoxChangeEvent) => {
    onChangeField("AssessmentID", event.target.value?.AssessmentID);
    onChangeField("Property_Address", event.target.value?.Assessment_Number);
    onChangeField(
      "Account_Reference",
      event.target.value?.Assessment_Reference
    );
    onChangeField(
      "Property_Address",
      event.target.value?.Property_Name_Address_Locality
    );
  };

  const handleModuleTypeChange = (event: ComboBoxChangeEvent) => {
    onChangeField("Integration_Module_Type_ENUM", event.target.value?.Key);
    onChangeField("Integration_Module_Type_Name", event.target.value?.Value);
    onChangeField("Item_Type", undefined);
    fetchApiByAlias("getItemTypeLOVsSlider");
  };

  const handleItemTypeChange = (event: ComboBoxChangeEvent) => {
    onChangeField("Item_Type", event.target.value?.Key);
  };
  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-2">
        <div className="cc-field">
          <CCLabel title="Module type" isMandatory />
          <Field
            name={nameOf("Integration_Module_Type_ENUM")}
            dataItemKey="Key"
            textField="Value"
            data={ddAccountsLOVs?.IntegrationModuleTypes ?? []}
            value={getDropdownValue(
              getValue("Integration_Module_Type_ENUM"),
              ddAccountsLOVs?.IntegrationModuleTypes ?? []
            )}
            validator={requiredValidator}
            onChange={handleModuleTypeChange}
            component={CCSearchComboBox}
          />
        </div>
        <div className="cc-field">
          <CCLabel title={"Item type"} isMandatory />
          <Field
            name={nameOf("Item_Type")}
            dataItemKey="Key"
            textField="Value"
            data={ddAccountsLOVs?.ItemTypes ?? []}
            value={getDropdownValue(
              getValue("Item_Type"),
              ddAccountsLOVs?.ItemTypes ?? []
            )}
            validator={requiredValidator}
            onChange={handleItemTypeChange}
            component={CCSearchComboBox}
            disabled={getItemTypeLOVsSlider.isFetching}
            isLoading={getItemTypeLOVsSlider.isFetching}
          />
        </div>
      </div>
      {getValue("Integration_Module_Type_ENUM") ===
        IntegrationModuleType.PropertyAssessment && (
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <CCLabel title="Property lookup" isMandatory />
            <Field
              name={nameOf("AssessmentID")}
              component={ComboboxDDSearchAPI}
              dataItemKey="AssessmentID"
              textField="Property_Name_Address_Locality"
              value={getValue("Property_Address")}
              urlAPI={ASSESSMENT_LOOKUP_API}
              keySearch="searchString"
              isFilterable={false}
              onChange={handlePropertyLookupChange}
              validator={
                getValue("Integration_Module_Type_ENUM") ===
                IntegrationModuleType.PropertyAssessment
                  ? requiredValidator
                  : undefined
              }
              allowCustom
            />
          </div>
        </div>
      )}
      {getValue("Integration_Module_Type_ENUM") ===
        IntegrationModuleType.PropertyRegister && (
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <CCLabel title={"Register account ID"} />
            <Field
              name={nameOf("Register_Account_ID")}
              placeholder={"Register account ID"}
              component={CCNumericTextBox}
              format={NUMBER_FORMAT.NUMBER2}
            />
          </div>
        </div>
      )}
      {getValue("Integration_Module_Type_ENUM") ===
        IntegrationModuleType.CommunityFinance && (
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <CCLabel title="Finance customer number" isMandatory />
            <Field
              name={nameOf("Finance_Customer_No")}
              placeholder={"Finance customer number"}
              component={ComboboxDDSearchAPI}
              urlAPI="api/receipting/internal/directdebitaccount/financecustomerlookup"
              keySearch="searchText"
              isFilterable={false}
              dataItemKey="Finance_Customer_No"
              textField="SearchText"
              value={getValue("_options")?.Finance_Customer_Display ?? ""}
              validator={
                getValue("Integration_Module_Type_ENUM") ===
                IntegrationModuleType.CommunityFinance
                  ? requiredValidator
                  : undefined
              }
              onChange={handleFinanceCustomerChange}
              onError={(error: APIResponse<ResponsePacket>) => {
                pushNotificationPortal({
                  title:
                    error?.data?.Errors?.length > 0
                      ? error?.data?.Errors
                      : "There is an error when searching finance customer number",
                  type: "error",
                  placeId: DD_CREATE_ACCOUNT,
                });
              }}
              allowCustom
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Finance invoice number" />
            <Field
              name={nameOf("Finance_Invoice_No")}
              placeholder={"Finance invoice number"}
              component={ComboboxDDSearchAPI}
              isFilterable={false}
              urlAPI="api/receipting/internal/directdebitaccount/financeinvoicelookup"
              disabled={!getValue("Finance_Customer_No")}
              keySearch="invoiceNumber"
            />
          </div>
        </div>
      )}
    </section>
  );
});
