import { eventEmitter } from "@/App";
import {
  getDirectDebitAccountLOVs,
  postDirectDebitAccount,
} from "@app/products/direct-debit/accounts/[id]/api";
import {
  ACCOUNT_DETAILS_STEP,
  ACCOUNT_GENERAL_STEP,
  DD_CREATE_ACCOUNT,
  PAYMENT_DETAILS_STEP,
} from "@app/products/direct-debit/accounts/components/dialogs/create-account/constant";
import { DDAccountDetailsStep } from "@app/products/direct-debit/accounts/components/dialogs/create-account/steps/account-details";
import {
  DDGeneralStep,
  IDirectDebitAccountFormStepProps,
} from "@app/products/direct-debit/accounts/components/dialogs/create-account/steps/general";
import { DDPaymentDetailsStep } from "@app/products/direct-debit/accounts/components/dialogs/create-account/steps/payment-details";
import {
  DirectDebitAccount,
  DirectDebitAccountLOVs,
  DirectDebitAccountStatus,
  DirectDebitAccountStatusFilter,
} from "@app/products/direct-debit/accounts/model";
import { useFlexibleFetchData } from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCFormStep, ICCFormStepRender } from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { TypePlaceId } from "@components/cc-notification-portal/constant";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import { Button } from "@progress/kendo-react-buttons";
import { clone } from "lodash";
import React from "react";
import { useEffectOnce } from "react-use";

export interface ICreateAccountDialogProps {
  onClose: () => void;
  isFromAuthorisation?: boolean;
}

export interface ICreateAccountDialogProps {
  onClose: () => void;
  isFromAuthorisation?: boolean;
}

export const DDCreateAccountDialog = ({
  onClose,
  isFromAuthorisation = false,
}: ICreateAccountDialogProps) => {
  const { pushNotificationPortal } = useNotificationPortalStore();
  const { setInitialDataForms, resetDataForm } = useFlexibleFormStore();
  const { cancelToken } = useCancelRequest();

  const steps: IStep[] = [
    {
      label: "General",
      component: DDGeneralStep,
      visible: true,
      key: ACCOUNT_GENERAL_STEP,
      options: {
        isFromAuthorisation,
      },
    },
    {
      label: "Account details",
      component: DDAccountDetailsStep,
      visible: true,
      key: ACCOUNT_DETAILS_STEP,
    },
    {
      label: "Payment details",
      component: DDPaymentDetailsStep,
      visible: true,
      key: PAYMENT_DETAILS_STEP,
    },
  ];

  const ddCreateAccountDialogLoadSlider = useFlexibleFetchData({
    slides: [
      {
        fetch: () => getDirectDebitAccountLOVs(cancelToken()),
        handleSuccess: ({ dataFromApi }) => {
          const response: DirectDebitAccountLOVs = dataFromApi?.data;
          setInitialDataForms({
            GeneralFormLovs: response,
          });
        },
        handleError: ({ errorFromApi }) => {
          pushNotificationPortal({
            title: "Direct debit initial data could not be loaded.",
            type: "error",
            description: errorFromApi?.error,
            placeId: DD_CREATE_ACCOUNT,
            autoClose: false,
          });
        },
      },
    ],
  });

  const ddCreateAccountDialogSubmitSlider = useFlexibleFetchData({
    slides: [
      {
        fetch: ({ initialData }) => {
          const ddInitialData: DirectDebitAccount = initialData;
          return postDirectDebitAccount(ddInitialData, cancelToken());
        },
        handleSuccess: () => {
          onClose();
          pushNotificationPortal({
            title: "Direct debit account successfully saved.",
            type: "success",
            placeId: TypePlaceId.Main,
          });

          eventEmitter.emit(CCGridEventType.RefreshOData);
        },
        handleError: ({ errorFromApi }) => {
          pushNotificationPortal({
            title: "Direct debit account could not be saved.",
            type: "error",
            description: errorFromApi?.error,
            placeId: DD_CREATE_ACCOUNT,
            autoClose: false,
          });
        },
      },
    ],
  });

  const handleSubmit = (data: any) => {
    const valueSubmit: IDirectDebitAccountFormStepProps = clone(data);
    delete valueSubmit._options;
    ddCreateAccountDialogSubmitSlider.fetchApi({
      initialData: valueSubmit,
    });
  };

  const handleReload = () => {
    ddCreateAccountDialogLoadSlider.fetchApi();
  };

  useEffectOnce(() => {
    handleReload();

    return () => {
      resetDataForm();
    };
  });

  return (
    <CCFormStep
      onSubmit={handleSubmit}
      initialSteps={steps}
      initialValues={{
        Start_Date: new Date(),
        Account_ID: 0,
        AccountStatus_ENUM: DirectDebitAccountStatus.Active,
        AccountStatus_Name: DirectDebitAccountStatusFilter.Active,
      }}
      renderForm={(renderProps: ICCFormStepRender) => (
        <CCDialog
          maxWidth="55%"
          maxHeight="65%"
          yieldNotificationPortal={DD_CREATE_ACCOUNT}
          titleHeader={"Create Direct Debit Account"}
          onClose={onClose}
          isLoading={ddCreateAccountDialogLoadSlider.isFetching}
          disabled={ddCreateAccountDialogSubmitSlider.isFetching}
          bodyElement={
            ddCreateAccountDialogLoadSlider.errorResponse ? (
              <CCLoadFailed
                responseError={ddCreateAccountDialogLoadSlider.errorResponse}
                onReload={handleReload}
              />
            ) : (
              renderProps.children
            )
          }
          footerElement={
            <div className={"cc-dialog-footer-actions-right"}>
              <Button
                className={"cc-dialog-button"}
                onClick={onClose}
                disabled={ddCreateAccountDialogLoadSlider.isFetching}
              >
                Cancel
              </Button>
              {!renderProps.prevButton.disabled && (
                <Button
                  className={"cc-dialog-button"}
                  themeColor="primary"
                  onClick={renderProps.prevButton.onClick}
                >
                  Previous
                </Button>
              )}
              <Button
                themeColor="primary"
                id="cc-next-step-button"
                iconClass={
                  ddCreateAccountDialogSubmitSlider?.isFetching
                    ? "fas fa-spinner fa-spin"
                    : ""
                }
                disabled={
                  renderProps.nextButton.disabled ||
                  ddCreateAccountDialogLoadSlider.isFetching
                }
                className={"cc-dialog-button"}
                onClick={renderProps.nextButton.onClick}
              >
                {renderProps.isLastStep
                  ? "Submit"
                  : renderProps.nextButton.label}
              </Button>
            </div>
          }
        />
      )}
    />
  );
};
