import { VO_NoticeOfSale_Title } from "@app/products/property/changes-of-ownership/notice-of-sales/components/detail/components/lot-details/model";
import { DECIMAL_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<VO_NoticeOfSale_Title>();
export const lotDetailsColumns: IColumnFields[] = [
  {
    field: nameOf("Property_Id"),
    title: "Property ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Owners_Name_Address"),
    title: "Owner Name and Address'",
  },
  {
    field: nameOf("Section_Number"),
    title: "Section Number",
  },
  {
    field: nameOf("Plan_Type"),
    title: "Plan Type",
  },
  {
    field: nameOf("Plan_Number"),
    title: "Plan Number",
  },
  {
    field: nameOf("Folio_Suffix"),
    title: "Folio Suffix",
  },
  {
    field: nameOf("Volume_Number"),
    title: "Volume Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Folio_Number"),
    title: "Folio Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("VF_Suffix"),
    title: "VF Suffix",
  },
  {
    field: nameOf("Auto_Consolidation_Flag"),
    title: "Auto Consolidate Flag",
  },
  {
    field: nameOf("Locality"),
    title: "Locality",
  },
  {
    field: nameOf("Title_Suffix"),
    title: "Title Suffix",
  },
  {
    field: nameOf("County"),
    title: "County",
  },
  {
    field: nameOf("Parish"),
    title: "Parish",
  },
  {
    field: nameOf("Property_Area"),
    title: "Property Area",
    format: DECIMAL_FORMAT.DECIMAL1,
  },
  {
    field: nameOf("Area_Type"),
    title: "Area Type",
  },
  {
    field: nameOf("Lot_Area"),
    title: "Lot Area",
    format: DECIMAL_FORMAT.DECIMAL1,
  },
  {
    field: nameOf("Lot_Area_Type"),
    title: "Lot Area Type",
  },
  {
    field: nameOf("Unit_Suffix"),
    title: "Unit Suffix",
  },
  {
    field: nameOf("Property_Premises_Type"),
    title: "Premises Type",
  },
  {
    field: nameOf("Lot_Type"),
    title: "Lot Type",
  },
  {
    field: nameOf("Lot_Number"),
    title: "Lot Number",
  },
  {
    field: nameOf("Country"),
    title: "Country",
  },
  {
    field: nameOf("Postal_Service_Type"),
    title: "Postal Service Type",
  },
  {
    field: nameOf("Postal_Service_Identifier"),
    title: "Postal Service Identifier",
  },
  {
    field: nameOf("Property_Delivery_Point_Id"),
    title: "Property Delivery Point Identifier",
  },
  {
    field: nameOf("LGA"),
    title: "LGA",
  },
  {
    field: nameOf("Formatted_Title_Reference"),
    title: "Formatted Title Reference",
  },
];
