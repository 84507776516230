import {
  DirectDebitAccount,
  DirectDebitAccountStatus,
  IntegrationModuleItemType,
} from "@app/products/direct-debit/accounts/model";
import { DBRowState } from "@common/models/baseClassStandard";
import { flexibleFormStoreInstance } from "@common/stores/flexible-form/store";

export const checkIsInactiveDirectDebitAccount = (): boolean => {
  const data = flexibleFormStoreInstance?.dataForms
    ?.GeneralForm as DirectDebitAccount;
  return (data?.Sys_DBRowState as DBRowState) === DBRowState.Inactive;
};
export const changeStatusNotification = (
  status: DirectDebitAccountStatus
): string => {
  switch (status) {
    case DirectDebitAccountStatus.Active:
      return `Set active direct debit`;
    case DirectDebitAccountStatus.Cancelled:
      return `Cancel direct debit`;
    case DirectDebitAccountStatus.Suspended:
      return `Suspend direct debit`;
    case DirectDebitAccountStatus.Terminated:
      return `Terminate direct debit`;
    default:
      return "";
  }
};

export const getKeyValueFromLOVs = (
  itemTypeData: IntegrationModuleItemType[]
) => {
  return itemTypeData.map((item) => {
    return {
      Key: item.Item_Type_ID,
      Value: item.Name,
    };
  });
};

export const checkDisabledStatus = (
  AccountStatus_ENUM: DirectDebitAccountStatus
): boolean => {
  return (
    AccountStatus_ENUM === DirectDebitAccountStatus.Cancelled ||
    AccountStatus_ENUM === DirectDebitAccountStatus.Terminated ||
    AccountStatus_ENUM === DirectDebitAccountStatus.Suspended
  );
};
