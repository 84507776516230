import { AddCommentPropertyButton } from "@app/core/comments/components/buttons/add-comment/components/add-comment-property/_index";
import { PropertyComments } from "@app/core/comments/components/property-comments/_index";
import { MailMergeButtonManagePage } from "@app/core/new-mail-merge/buttons/mail-merge-manage-page/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { ChangeOfOwnerShipButton } from "@app/products/property/assessments/components/action-bar/buttons/change-of-ownership/_index";
import { AddAttachmentPropertyButton } from "@app/products/property/components/action-bar/button/add-attachment/_index";
import { eDocumentTypeCategory } from "@app/products/property/components/action-bar/button/add-attachment/model";
import { CollectionsButton } from "@app/products/property/components/action-bar/button/collections/_index";
import { PropertyAssociations } from "@app/products/property/components/associations/_index";
import { eComponent } from "@app/products/property/components/associations/model";
import { PropertyActions } from "@app/products/property/components/child-screen/actions";
import { PropertyCollections } from "@app/products/property/components/child-screen/collections/_index";
import { PropertyDocuments } from "@app/products/property/components/child-screen/documents/_index";
import { GISAccordion } from "@app/products/property/components/gis-accordion/_index";
import { LineageAccordion } from "@app/products/property/components/lineage-accordion/_index";
import {
  IPropertyPanelBar,
  PropertyPanelBar,
} from "@app/products/property/components/panel-bar/_index";
import { PropertyContactTab } from "@app/products/property/components/reference-sidebar/contact/_index";
import { PropertyHistoryTab } from "@app/products/property/components/reference-sidebar/property-history/_index";
import { ECustomColNameProperty } from "@app/products/property/config";
import { CreatePICButton } from "@app/products/property/pic/list/components/action-bar/buttons/new-pic/_index";
import { ModifyGISReferenceTitleButton } from "@app/products/property/titles/[id]/components/buttons/modify-gis-reference-title/_index";
import { AssociateTitle } from "@app/products/property/titles/[id]/components/child-screens/associated-titles/_index";
import { TitleAttributesUDA } from "@app/products/property/titles/[id]/components/child-screens/attributes-uda/_index";
import { PropertyTitleForm } from "@app/products/property/titles/[id]/components/child-screens/general/_index";
import TitleOwners from "@app/products/property/titles/[id]/components/child-screens/owners/_index";
import { TitleContacts } from "@app/products/property/titles/[id]/components/child-screens/title-contacts/_index";
import { TitleTransfer } from "@app/products/property/titles/[id]/components/child-screens/transfer/_index";
import { lotLineageCols } from "@app/products/property/titles/[id]/components/forms/existed/config";
import { TitlesAssociationsTab } from "@app/products/property/titles/[id]/components/reference-sidebar/associations/_index";
import { TitleDetailTab } from "@app/products/property/titles/[id]/components/reference-sidebar/detail/_index";
import { useTitleStore } from "@app/products/property/titles/[id]/store";
import { ModifyTitleButton } from "@app/products/property/titles/list/components/action-bar/buttons/modify-title/_index";
import { AddActionWithParentButton } from "@app/products/town-planning/ppr/components/action-bar/buttons/add/action/_index";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useGlobalStore } from "@common/stores/global/store";
import { Label } from "@common/stores/products/config";
import { OrganisationMode } from "@common/stores/products/model";
import { useCommonProductStore } from "@common/stores/products/store";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import { MapTab } from "@components/cc-reference-sidebar/components/Map/_index";
import { eAccordionType } from "@components/custom-panelbar/model";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

const ExistedPropertyTitle = observer(() => {
  const {
    title,
    titleId,
    isLoading,
    resetStore,
    responseLoadError,
    loadTitle,
  } = useTitleStore();
  const [showSlideBar, setShowSlideBar] = useState(true);
  const { id } = useParams<{ id: string }>();
  const managePageUrl = window.location.pathname;
  const { currentUserInfo } = useGlobalStore();
  const { currentOrganisationMode } = useCommonProductStore();
  const isLLS = currentOrganisationMode(OrganisationMode.LLS);

  //Get labels
  const titleLabel = Label.CommunityProperty.getLabel(
    ECustomColNameProperty.Title
  );

  const bookmarkList: IBookmarkItem[] = [
    {
      Text: `${getDisplayTextWithDashes([
        title?.titleSummary?.LegalDescription,
      ])}`,
      LinkUrl: managePageUrl,
      LinkText: `Property - ${titleLabel} - ${titleId}`,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.CommunityProperty,
      RecordType_ENUM: RECORDTYPE.CommunityProperty_Unknown,
      Record_ID: titleId,
    },
  ];

  useEffectOnce(() => {
    return () => {
      resetStore();
    };
  });

  const listPanelBar: IPropertyPanelBar[] = [
    {
      title: "Actions and Workflows",
      component: (
        <PropertyActions
          id={parseInt(id)}
          recordType={RECORDTYPE.CommunityProperty_Title}
          componentNumber={eComponent.Title}
        />
      ),
      accordionType: eAccordionType.Actions_And_Workflows,
    },
    {
      title: `Associated ${titleLabel}`,
      component: <AssociateTitle />,
    },
    {
      title: "Related",
      component: (
        <PropertyAssociations
          id={title?.titleId}
          componentNumber={eComponent.Title}
        />
      ),
    },
    {
      title: "Lineage",
      component: (
        <LineageAccordion
          apiUrl={`/api/property/internal/lot/${parseInt(id)}/lineage`}
          lineageCols={lotLineageCols}
        />
      ),
    },
    {
      title: "Owners",
      component: <TitleOwners />,
    },
    // Requirement: https://dev.azure.com/openofficeau/Radium/_workitems/edit/12802/
    // {
    //   title: "Audit",
    //   component: (
    //     <PropertyAudit id={parseInt(id)} componentNumber={eComponent.Title} />
    //   ),
    // },
    {
      title: "Attributes",
      component: <TitleAttributesUDA />,
      isVisible: !isLLS,
    },
    {
      title: "Comments",
      component: (
        <PropertyComments
          id={parseInt(id)}
          recordType={RECORDTYPE.CommunityProperty_Title}
        />
      ),
    },
    {
      title: "Contacts",
      component: <TitleContacts />,
    },
    {
      title: "Collections",
      component: (
        <PropertyCollections
          componentId={parseInt(id)}
          componentNumber={eComponent.Title}
        />
      ),
    },
    {
      title: "Documents",
      component: (
        <PropertyDocuments
          componentId={parseInt(id)}
          componentNumber={eComponent.Title}
        />
      ),
    },
    {
      title: "Transfers",
      component: <TitleTransfer />,
    },
    {
      title: "GIS References",
      component: (
        <GISAccordion
          apiUrl={`/api/property/int/gisreference/${
            eComponent.Title
          }/${parseInt(id)}`}
        />
      ),
    },
  ];

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title={titleLabel} />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadTitle(+id)}
        />
      ) : (
        <>
          <FormTitle title={title?.titleDetail?.LegalDescription} />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Workflow"}
                type="sub-workflow"
                className="cc-workflow-button"
              >
                <CollectionsButton
                  id={parseInt(id)}
                  propertyComponent={eComponent.Title}
                />
                <ModifyGISReferenceTitleButton />
                <CCNavButton title={titleLabel} type="dropdown">
                  <ModifyTitleButton />
                </CCNavButton>
                <ChangeOfOwnerShipButton componentNumber={eComponent.Title} />
                {isLLS ? (
                  <CCNavButton title={"PIC"} type="dropdown">
                    <CreatePICButton titleIds={[+id]} />
                  </CCNavButton>
                ) : (
                  <></>
                )}
              </CCNavButton>,
              <CCNavButton title={"Add"} type="sub">
                <AddActionWithParentButton
                  id={parseInt(id)}
                  recordType={RECORDTYPE.CommunityProperty_Title}
                />
                <AddAttachmentPropertyButton
                  id={parseInt(id)}
                  recordType={RECORDTYPE.CommunityProperty_Title}
                  documentCategoryTypeId={eDocumentTypeCategory.Title_Document}
                />
                <AddCommentPropertyButton
                  id={parseInt(id)}
                  recordType={RECORDTYPE.CommunityProperty_Title}
                />
                <MailMergeButtonManagePage
                  productType={PRODUCT_TYPE_NUMBER.CommunityProperty}
                  dataSetFilter={MAILMERGEDATASET.COMMUNITYPROPERTY_Title}
                  recordType={RECORDTYPE.CommunityProperty_Title}
                  isEmptyGridSelectedRow={!parseInt(id)}
                  selectedRecordIds={[parseInt(id)]}
                />
              </CCNavButton>,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,

              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />
          <div className="cc-manage-container">
            <div className="cc-form cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {title && <PropertyTitleForm />}
                <PropertyPanelBar
                  initialPanels={listPanelBar}
                  componentId={parseInt(id)}
                  componentNumber={eComponent.Title}
                />
              </div>
            </div>
            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar
                  components={[
                    { title: "Details", component: <TitleDetailTab /> },
                    {
                      title: "Map",
                      //TODO: Update after api add location field
                      component: <MapTab />,
                      // 15305 - hide "Map" for all
                      isVisible: false,
                    },
                    {
                      title: "Contacts",
                      component: (
                        <PropertyContactTab
                          id={titleId}
                          componentNumber={eComponent.Title}
                        />
                      ),
                    },
                    {
                      title: "Related",
                      component: <TitlesAssociationsTab />,
                    },
                    {
                      title: "History",
                      component: (
                        <PropertyHistoryTab
                          recordType={RECORDTYPE.CommunityProperty_Title}
                        />
                      ),
                    },
                  ]}
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});

export default ExistedPropertyTitle;
