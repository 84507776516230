import { Svc_Contact } from "@app/core/contacts/_id/model";
import { KeyValuePacket } from "@app/products/crms/components/dialogs/clone-new-event/model";
import {
  IntegrationModuleType,
  PaymentFrequency,
} from "@app/products/direct-debit/model";
import { DTOBaseClass_Standard } from "@common/models/baseClassStandard";

export interface DirectDebitAccount extends DTOBaseClass_Standard {
  Account_ID: number;
  Account_Number: string;
  Authorisation_Id?: number;
  Authorisation_Number?: string;
  Register_ID?: number;
  State: string;
  Direct_Debit_Group_ID?: number;
  Direct_Debit_Group_Name?: string;
  Payment_Frequency_ENUM: PaymentFrequency;
  Payment_Frequency_Name: string;
  Integration_Module_Type_ENUM: IntegrationModuleType;
  Integration_Module_Type_Name: string;
  Contact_ID?: number;
  Register_Account_ID?: number;
  AssessmentID?: number;
  Assessment_Number?: string;
  Assessment_Reference: string;
  Name: string;
  Name_2: string;
  Address: string;
  Address_2: string;
  Locality: string;
  Post_Code: string;
  Finance_Customer_No: string;
  Finance_Invoice_No: string;
  Balance?: number;
  Balance_Date?: Date;
  Manual_Description: string;
  Amount?: number;
  Start_Date?: Date;
  End_Date?: Date;
  Next_Transfer_Date?: Date;
  AccountStatus_ENUM: DirectDebitAccountStatus;
  AccountStatus_Name: string;
  Balance_Only: boolean;
  Item_Type?: number;
  Stop_When_Balance_Zero?: boolean;
  Account_Reference: string;
  Transfer_Amount?: number;
  Instalment_Only: boolean;
  Instalment?: number;
  Instalment_Date?: Date;
  Property_Address: string;
  Core_Contact_VIP: boolean;
}

export enum DirectDebitAccountStatus {
  Unspecified = 0,
  Active = 1,
  Suspended = 2,
  Terminated = 3,
  Cancelled = 4,
  Archived = 5,
  CancelledbyChangeofOwnership = 6,
}

export enum DirectDebitAccountStatusFilter {
  "Active" = "Active",
  "Suspended" = "Suspended",
  "Terminated" = "Terminated",
  "Cancelled" = "Cancelled",
  "Archived" = "Archived",
  "Cancelled by Change of Ownership" = "Cancelled by Change of Ownership",
}
export interface DirectDebitAccountLOVs {
  IntegrationModuleTypes: KeyValuePacket[];
  PaymentFrequencies: KeyValuePacket[];
  ItemTypes: KeyValuePacket[];
  IncludeGroups: KeyValuePacket[];
  AuthorisationsList: KeyValuePacket[];
}

export interface IntegrationModuleItemType extends DTOBaseClass_Standard {
  Integration_Module_Item_Type_ID: number;
  Integration_Module_Item_Type_Number: string;
  Integration_Module_Type_ENUM: IntegrationModuleType;
  Integration_Module_Type_Name: string;
  Register_ID: number | null;
  Name: string;
  Item_Type_ID: number | null;
}

export interface Svc_Contact_With_ID extends Svc_Contact {
  Contact_ID?: number;
}

export interface FinanceCustomer extends DTOBaseClass_Standard {
  CustomerNo: string;
  Name: string;
  Name2: string;
  Address: string;
  Address2: string;
  City: string;
  State: string;
  PostCode: string;
  CustomerPostingGroup: string;
  Blocked: string;
  Abn: string;
  Balance: number | null;
  Email: string;
  PhoneNo: string;
  LastPaymentDate: Date | null;
  SearchText: string;
  WebClientURL: string;
}
