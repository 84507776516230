import { TEMPORARY_FIELDS } from "@common/stores/flexible-form/constant";
import {
  TDataFormField,
  TFormName,
  TMiddlewareSubmitFormOptions,
  TOnSubmitFlexible,
} from "@common/stores/flexible-form/model";
import { FormSubmitClickEvent } from "@progress/kendo-react-form";
import { isNil, mergeWith } from "lodash";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

type TState = any;
class FlexibleFormStore {
  private _dataRoute: Record<string, TState> = {};
  private _route: string = window.location.pathname;
  private _isSubmit: boolean = false;
  private _isCancelSubmit: boolean = false;
  private _isLoadingForm: boolean = false;
  private _skipCheckInternal: boolean = false;
  private _initialEvent: any = undefined;
  private _middlewareSubmitFormOptions: TMiddlewareSubmitFormOptions = {
    skipCheckModified: false,
    skipCheckValidate: false,
  };
  private _onSubmitFlexible: Partial<
    Record<TFormName, (event: React.SyntheticEvent<any, Event>) => void>
  > = {};

  constructor() {
    makeAutoObservable(this);
    const fragment = document
      .createRange()
      .createContextualFragment("<button/>");
    const button = fragment.querySelector("button");
    const getInitialEvent = (event: Event) => this.setInitialEvent(event);
    button?.addEventListener("click", getInitialEvent);
    button?.click();
    button?.remove();
    button?.removeEventListener("click", getInitialEvent);
  }
  onSubmitFlexible(formName: TFormName) {
    const eventSubmit = this._onSubmitFlexible[formName];
    return toJS(eventSubmit);
  }
  setOnSubmitFlexible = ({ formName, eventSubmit }: TOnSubmitFlexible) => {
    runInAction(() => {
      this._onSubmitFlexible[formName] = eventSubmit;
    });
  };

  get middlewareSubmitFormOptions() {
    return toJS(this._middlewareSubmitFormOptions);
  }
  setMiddlewareSubmitFormOptions = (options: TMiddlewareSubmitFormOptions) => {
    runInAction(() => {
      this._middlewareSubmitFormOptions = options;
    });
  };

  setSkipCheckInternal = (status: boolean) => {
    runInAction(() => {
      this._skipCheckInternal = status;
    });
  };

  get isSubmit() {
    return toJS(this._isSubmit);
  }
  setIsSubmit = (status: boolean) => {
    runInAction(() => {
      this._isSubmit = status;
    });
  };

  get isCancelSubmit() {
    return toJS(this._isCancelSubmit);
  }
  setIsCancelSubmit = (status: boolean) => {
    runInAction(() => {
      this._isCancelSubmit = status;
    });
  };

  setInitialEvent = (event: Event) => {
    runInAction(() => {
      this._initialEvent = event;
    });
  };

  get isLoadingForm() {
    return toJS(this._isLoadingForm);
  }
  setIsLoadingForm = (status: boolean) => {
    runInAction(() => {
      this._isLoadingForm = status;
    });
  };

  get dataRoute() {
    return toJS(this._dataRoute);
  }
  setDataRoute = (route: string, dataFormItem: TState) => {
    runInAction(() => {
      this._dataRoute[route] = dataFormItem;
    });
  };

  //#region Listen submit event ========/
  waitForSubmit() {
    return new Promise((resolve, reject) => {
      if (this.isCancelSubmit) {
        reject("Cancelled Submit");
        return;
      }
      if (this.isSubmit) resolve("Submit");
    });
  }
  //#endregion Listen submit event =====/

  //#region Submit Form ========/
  submitFormGetData = async (
    formName: TFormName,
    options?: TMiddlewareSubmitFormOptions
  ) => {
    if (options) {
      this.setMiddlewareSubmitFormOptions(options);
    }
    try {
      if (this.onSubmitFlexible && this._initialEvent) {
        const submitAction = this.onSubmitFlexible(formName);
        if (submitAction) submitAction(this._initialEvent);
      }
      await this.waitForSubmit();
      this.setIsSubmit(false);
      this.setMiddlewareSubmitFormOptions({
        skipCheckModified: false,
        skipCheckValidate: false,
      });
      this.setSkipCheckInternal(false);
      if (options && options.removeParamOptions && this.dataForms) {
        delete this.dataForms[formName]?.[TEMPORARY_FIELDS];
        return this.dataForms[formName];
      }
      return this.dataForms[formName];
    } catch (e) {}
  };
  //#endregion Submit Form =====/

  //#region Handle Submit Form ========/
  middlewareSubmitForm = (
    event: FormSubmitClickEvent,
    otherCondition?: () => boolean
  ) => {
    this.setIsSubmit(false);
    if (
      this._skipCheckInternal ||
      (otherCondition && otherCondition()) ||
      this.middlewareSubmitFormOptions?.skipCheckValidate ||
      (event.isValid &&
        (event.isModified ||
          this.middlewareSubmitFormOptions?.skipCheckModified))
    ) {
      this.setIsCancelSubmit(false);
      this.setInitialDataForms({ GeneralForm: event.values });
    } else {
      this.setIsCancelSubmit(true);
    }
    return {
      whenNotValid: (callBack: Function) =>
        !event.isValid && !this.middlewareSubmitFormOptions?.skipCheckValidate
          ? callBack()
          : null,
      whenNotModified: (callBack: Function) =>
        event.isValid &&
        !event.isModified &&
        !this.middlewareSubmitFormOptions?.skipCheckModified
          ? callBack()
          : null,
    };
  };
  //#endregion Handle Submit Form =====/

  //#region Get Set Data for form when submit ========/
  get dataForms() {
    return toJS(this._dataRoute[this._route]) as TDataFormField;
  }
  setInitialDataForms = (dataFormParam: TDataFormField) => {
    const prevDataForm = this.dataRoute[this._route];
    const newDataForm: TDataFormField = prevDataForm
      ? { ...prevDataForm, ...dataFormParam }
      : dataFormParam;
    this.setDataRoute(this._route, newDataForm);
    this.setIsSubmit(true);
  };

  updateFormFields = async (
    formName: TFormName,
    dataUpdate: Record<string, any>,
    isInitialValue?: boolean
  ) => {
    const data = await this.submitFormGetData(formName, {
      skipCheckModified: true,
      skipCheckValidate: true,
    });
    if (data)
      this.setInitialDataForms({
        [formName]: mergeWith(
          data,
          dataUpdate,
          (objValue, srcValue, key, obj) => {
            if (objValue !== srcValue && typeof srcValue === "undefined") {
              obj[key] = srcValue;
            }
          }
        ),
      });
    if (isNil(isInitialValue)) {
      this.setMiddlewareSubmitFormOptions({
        skipCheckModified: true,
        skipCheckValidate: true,
      });
    }
    this.setSkipCheckInternal(true);
  };
  //#endregion Get Set Data for form when submit =====/

  //#region Reset Data Form ========/
  resetDataForm = () => {
    runInAction(() => {
      this.setDataRoute(this._route, {});
    });
  };
  //#endregion Reset Data Form =====/
}
export const flexibleFormStoreInstance = new FlexibleFormStore();
const flexibleFormStoreContext = createContext(flexibleFormStoreInstance);
export const useFlexibleFormStore = () => {
  return useContext(flexibleFormStoreContext);
};
