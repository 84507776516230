import { eventEmitter } from "@/App";
import { COMMENT_ACCORDION_GRID_ID } from "@app/core/comments/constant";
import { VO_Workflow_Draft } from "@app/products/property/actions/model";
import { listSubmitButton } from "@app/products/property/assessments/components/form-steps/new-assessment/config";
import {
  getInitialDataChangeOfOwnershipNoS,
  postProcessChangeOfOwnershipNoS,
} from "@app/products/property/changes-of-ownership/notice-of-sales/components/form-steps/update-notice-of-sale/api";
import { DetailsFormStep } from "@app/products/property/changes-of-ownership/notice-of-sales/components/form-steps/update-notice-of-sale/components/form-elements/detail/_index";
import { NamesFormStep } from "@app/products/property/changes-of-ownership/notice-of-sales/components/form-steps/update-notice-of-sale/components/form-elements/names/_index";
import { PropertyFormStep } from "@app/products/property/changes-of-ownership/notice-of-sales/components/form-steps/update-notice-of-sale/components/form-elements/property/_index";
import { TypeNoticeOfSaleFormStep } from "@app/products/property/changes-of-ownership/notice-of-sales/components/form-steps/update-notice-of-sale/components/form-elements/type/_index";
import {
  DTO_ChangeOfOwnership_NoticeOfSale_LOVs,
  DTO_Workflow_ChangeOfOwnership_NoticeOfSale,
  EKeysOfStepsCOONoS,
  RequestChangeOfOwnershipObj,
  keysOfSendStepsCOONoS,
} from "@app/products/property/changes-of-ownership/notice-of-sales/components/form-steps/update-notice-of-sale/model";
import { useChangeOfOwnershipNoSDialogStore } from "@app/products/property/changes-of-ownership/notice-of-sales/components/form-steps/update-notice-of-sale/store";
import { useConfirmCancelDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-cancel/store";
import { useConfirmCloseDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-close/store";
import { useConfirmFinishDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-finish/store";
import { useConfirmReallocateDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-reallocate/store";
import { useConfirmRejectDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-reject/store";
import { useConfirmSendBackDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-send-back/store";
import { CommentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/comments/_index";
import { DocumentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/documents/_index";
import { WorkflowStepFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/workflow/_index";
import {
  getSuffixTitle,
  secondaryWorkflowUtilProcessing,
} from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/workflow/util";
import { usePropertyWorkflow } from "@app/products/property/components/action-bar/property-workflow/component/hooks/useProprtyWorkflow/usePropertyWorkflow";
import { INewProcessWorkflow } from "@app/products/property/components/action-bar/property-workflow/model";
import { getTitleWorkflow } from "@app/products/property/components/action-bar/property-workflow/util";
import { PropertyDocumentEventType } from "@app/products/property/components/child-screen/documents/constant";
import {
  EListSubmitButton,
  EWorkflowStatus,
  WorkflowProcessMode,
  WorkflowTypes,
} from "@app/products/property/model";
import { isShowParkButton } from "@app/products/property/util";
import { APIResponse } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ResponsePacket } from "@common/models/identityPacket";
import { OrganisationMode } from "@common/stores/products/model";
import { useCommonProductStore } from "@common/stores/products/store";
import { getDropdownValue } from "@common/utils/common";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDialog } from "@components/cc-dialog/_index";
import {
  CCFormStep,
  ICCFormStepNotificationHandle,
  ICCFormStepRender,
} from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { CCGridEventType } from "@components/cc-grid/constant";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { Button } from "@progress/kendo-react-buttons";
import { head, isNil, pickBy } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useEffectOnce } from "react-use";
interface ILRSChangeOfOwnershipDialogProps {
  noticeOfSaleId: number;
  statusId: number;
  dataFromActionList?: VO_Workflow_Draft;
  isSaveOnNextStep?: boolean;
  prefixTitle?: string;
  suffixTitle?: string;
  onReject?: (data: any) => void;
  onClose: () => void;
  onSubmit: (data: any) => void;
}

export const UpdateNoticeOfSaleDialog = observer(
  (props: ILRSChangeOfOwnershipDialogProps) => {
    const {
      noticeOfSaleId,
      statusId,
      prefixTitle,
      suffixTitle,
      onClose,
      dataFromActionList,
      isSaveOnNextStep = false,
    } = props;

    //#region HOOKS
    //#region useRef
    const notificationFormStepRef =
      useRef<ICCFormStepNotificationHandle | null>(null);
    //#endregion useRef

    //#region useStore
    const { currentFormTitle } = useCommonProductStore();
    const { currentOrganisationMode } = useCommonProductStore();
    const { changeOfOwnershipNoSLOVs, setChangeOfOwnershipNoSLOVs } =
      useChangeOfOwnershipNoSDialogStore();
    const { setDataForRejectDialog } = useConfirmRejectDialogStore();
    const { setDataForFinishDialog } = useConfirmFinishDialogStore();
    const { setDataForCancelDialog } = useConfirmCancelDialogStore();
    const { setDataForCloseDialog, setIsLoadingClose } =
      useConfirmCloseDialogStore();
    const { setDataForSendBackDialog } = useConfirmSendBackDialogStore();
    const { setDataForReallocateDialog } = useConfirmReallocateDialogStore();
    const { pushNotification } = useCCAppNotificationStore();
    const { clearSelectedItems } = useCCProductListViewStore();
    //#endregion useStore

    //#region useState
    const [workflowInitialData, setWorkflowInitialData] =
      useState<DTO_Workflow_ChangeOfOwnership_NoticeOfSale>();
    const [isLoadingProcess, setIsLoadingProcess] = useState<
      WorkflowProcessMode | undefined
    >();
    const [selectedTypeId, setSelectedTypeId] = useState<number>();
    const [titleHeader, setTitleHeader] = useState<string>("");
    //#endregion useState

    const {
      isFromActionList,
      isIncompleteMode,
      isReadOnly,
      isShowCancelWorkflowButton,
      isToBeApprovalMode,
      statusBadge,
    } = usePropertyWorkflow(dataFromActionList);

    //#region useMemo
    const workflowDraftId = useMemo(() => {
      return (
        workflowInitialData?.WorkflowHeader?.WorkflowDraft?.Workflow_Draft_Id ??
        0
      );
    }, [workflowInitialData]);

    const isFirstSave = useMemo(() => {
      const draftId =
        workflowInitialData?.WorkflowHeader?.WorkflowDraft?.Workflow_Draft_Id;
      return isNil(draftId) || draftId === 0;
    }, [workflowInitialData]);

    const defaultTitle = useMemo(() => {
      const formId =
        workflowInitialData?.WorkflowHeader?.WorkflowDraft?.WD_Form_Id;
      return (
        currentFormTitle(formId ?? 0) ?? "Change of Ownership Notice of Sale"
      );
      // eslint-disable-next-line
    }, [workflowInitialData?.WorkflowHeader]);

    const initialValues = useMemo(() => {
      const workflowDetail = workflowInitialData?.WorkflowDetail;

      // Type + Details
      let initType = {
        _option: {
          Type: undefined,
        },
      };
      let initDetails: any = {};
      if (workflowDetail?.COODetails) {
        const selectedType = getDropdownValue(
          workflowDetail.COODetails.Type ?? 0,
          changeOfOwnershipNoSLOVs?.ChangeOfOwnershipTypes ?? [],
          "Change_of_Ownership_Type"
        );

        initType = {
          ...initType,
          ...workflowDetail.COODetails,
          _option: {
            Type: selectedType,
          },
        };

        initDetails = {
          ...initDetails,
          ...workflowDetail.COODetails,
        };
      }

      // Related/Property
      let initProperty: any = {};
      if (workflowDetail?.PropertyDetails) {
        initProperty = {
          ...initProperty,
          ...workflowDetail.PropertyDetails,
        };
      }

      // Document
      let initDocuments: {
        Document_Type_Id: number[];
      } = {
        Document_Type_Id: [],
      };
      if (workflowDetail?.Documents) {
        initDocuments = {
          ...initDocuments,
          ...workflowDetail.Documents,
        };
      }

      //Workflow
      const initialWorkflow =
        secondaryWorkflowUtilProcessing.processDataInit(workflowInitialData);

      return {
        [EKeysOfStepsCOONoS.Type]: initType,
        [EKeysOfStepsCOONoS.Details]: initDetails,
        [EKeysOfStepsCOONoS.Property]: initProperty,
        [EKeysOfStepsCOONoS.Names]: {}, // @TODO - 14306: Hidden for LLS, will be handled in other task
        [EKeysOfStepsCOONoS.Comments]: {},
        [EKeysOfStepsCOONoS.Documents]: initDocuments,
        [EKeysOfStepsCOONoS.Workflow]: initialWorkflow,
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workflowInitialData, changeOfOwnershipNoSLOVs]);
    //#endregion useMemo

    //#region useEffect
    useEffect(() => {
      let formTitle = "";
      if (!selectedTypeId) {
        formTitle = defaultTitle;
      } else {
        const typeName = getDropdownValue(
          selectedTypeId,
          changeOfOwnershipNoSLOVs?.ChangeOfOwnershipTypes ?? [],
          "Change_of_Ownership_Type"
        )?.COOT_Name;
        formTitle = typeName ? typeName + " Notice Of Sale" : defaultTitle;
      }
      setTitleHeader(
        getTitleWorkflow(
          formTitle,
          prefixTitle,
          getSuffixTitle(
            suffixTitle,
            isToBeApprovalMode,
            workflowInitialData?.WorkflowHeader?.WorkflowApprovals ?? []
          )
        )
      );
    }, [
      defaultTitle,
      isToBeApprovalMode,
      workflowInitialData?.WorkflowHeader,
      prefixTitle,
      suffixTitle,
      selectedTypeId,
      changeOfOwnershipNoSLOVs?.ChangeOfOwnershipTypes,
    ]);

    /**
     * Gets workflow data
     *
     * @returns {Promise} A promise that resolves with the workflow data.
     */
    const getWorkflowData = async () => {
      notificationFormStepRef?.current?.setLoadingFormStep(true);
      const payload: RequestChangeOfOwnershipObj = {
        NoticeOfSale_Id: noticeOfSaleId,
        StatusId: statusId,
        Assessment_Group_Id: null,
      };
      const response = await getInitialDataChangeOfOwnershipNoS(
        payload,
        dataFromActionList?.Workflow_Draft_Id
      );
      notificationFormStepRef?.current?.setLoadingFormStep(false);
      if (Array.isArray(response)) {
        const [lovsResponse, workflowResponse] = response;
        const lovsData = lovsResponse?.data;
        const workflowData = workflowResponse?.data;
        if (
          isSuccessResponse(lovsResponse) &&
          isSuccessResponse(workflowResponse) &&
          lovsData &&
          workflowData
        ) {
          //Set workflow LOVs
          setChangeOfOwnershipNoSLOVs(lovsData);
          //Set workflow initial data
          setWorkflowInitialData(workflowData);
        } else {
          let responseError: APIResponse<
            | DTO_ChangeOfOwnership_NoticeOfSale_LOVs
            | DTO_Workflow_ChangeOfOwnership_NoticeOfSale
            | ResponsePacket
            | undefined
          > = lovsResponse;
          if (!isSuccessResponse(workflowResponse)) {
            responseError = workflowResponse;
          }
          notificationFormStepRef?.current?.setLoadFailedFormStep({
            onReload: () => getWorkflowData(),
            responseError: {
              status: responseError.status,
              error:
                (responseError.data as ResponsePacket)?.Errors ??
                "Load workflow failed",
            },
          });
        }
      }
    };

    useEffectOnce(() => {
      getWorkflowData();
    });
    //#endregion useEffect
    //#endregion HOOKS

    //#region IMMUTABLE VALUES
    const isLLS = currentOrganisationMode(OrganisationMode.LLS);
    const steps: IStep[] = [
      {
        label: "Type",
        initialValues: initialValues[EKeysOfStepsCOONoS.Type],
        component: TypeNoticeOfSaleFormStep,
        visible: true,
        key: EKeysOfStepsCOONoS.Type,
        options: {
          isReadOnly,
        },
      },
      {
        label: "Details",
        initialValues: initialValues[EKeysOfStepsCOONoS.Details],
        component: DetailsFormStep,
        visible: true,
        key: EKeysOfStepsCOONoS.Details,
        options: {
          isReadOnly,
          isLLS,
        },
      },
      {
        label: isLLS ? "Related" : "Property",
        initialValues: initialValues[EKeysOfStepsCOONoS.Property],
        component: PropertyFormStep,
        visible: true,
        key: EKeysOfStepsCOONoS.Property,
        options: {
          isReadOnly,
        },
      },
      // @TODO - 14306: Hidden for LLS, will be handled in other task
      {
        label: "Names",
        initialValues: initialValues[EKeysOfStepsCOONoS.Names],
        component: NamesFormStep,
        visible: !isLLS,
        key: EKeysOfStepsCOONoS.Names,
        options: {
          isReadOnly,
        },
      },
      {
        label: "Comments",
        initialValues: initialValues[EKeysOfStepsCOONoS.Comments],
        component: CommentsFormStep,
        visible: true,
        key: EKeysOfStepsCOONoS.Comments,
        customClassName: "cc-comment-step-fixed-height-grid",
        options: {
          isReadOnly,
          workflowDraftId,
          recordType: RECORDTYPE.CommunityProperty_Change_of_Ownership,
        },
      },
      {
        label: "Documents",
        component: DocumentsFormStep,
        initialValues: initialValues[EKeysOfStepsCOONoS.Documents],
        visible: true,
        key: EKeysOfStepsCOONoS.Documents,
        options: {
          isReadOnly,
          workflowDraftId,
          workflowType: WorkflowTypes.Change_Of_Ownership_NoticeOfSale,
          subType: selectedTypeId,
          isFromActionList,
        },
      },
      {
        label: "Workflow",
        initialValues: initialValues[EKeysOfStepsCOONoS.Workflow],
        component: WorkflowStepFormStep,
        visible: true,
        key: EKeysOfStepsCOONoS.Workflow,
        options: {
          isReadOnly,
          isFromActionList,
          dataFromActionList,
        },
      },
    ];
    //#endregion IMMUTABLE VALUES

    //#region COMMON FUNCTIONS
    /**
     * Handles form submission.
     *
     * @param {any} data The data to be submitted.
     * @param {string} [buttonId] Optional. The ID of the button triggering the submission.
     * @returns {Promise<void>} A promise that resolves once the submission is complete.
     */
    const handleSubmit = async (data: any, buttonId?: string) => {
      switch (buttonId) {
        case EListSubmitButton.Approve:
          await handleApproveProcess(processData(data));
          break;
        case EListSubmitButton.Save:
          await handleSaveProcess(processData(data), true);
          break;
        case EListSubmitButton.SaveWorkflow:
          await handleSaveProcess(processData(data), false, true);
          break;
        case EListSubmitButton.Finish:
          handleConfirmFinishProcess(data);
          break;
        case EListSubmitButton.Cancel:
        case EListSubmitButton.ConfirmCloseNo:
          handleCancelButton(processData(data));
          break;
        case EListSubmitButton.ConfirmCloseYes:
          handleParkProcess(
            processData(data),
            EListSubmitButton.ConfirmCloseYes
          );
          break;
        case EListSubmitButton.SendBack:
          handleConfirmSendBackProcess(processData(data));
          break;
        case EListSubmitButton.Reallocate:
          handleConfirmReallocateProcess(processData(data));
          break;
        case EListSubmitButton.Reject:
          handleRejectButton(processData(data));
          break;
        case EListSubmitButton.Park:
        case EListSubmitButton.Close:
          await handleParkProcess(processData(data));
          break;
      }
    };

    /**
     * Processes the provided data.
     *
     * @param {any} data The data to be processed.
     * @returns {void} This function does not return anything.
     */
    const processData = (data: any) => {
      let workflowDetail: any = {};
      let workflowHeader: any = { ...workflowInitialData?.WorkflowHeader };
      data[EKeysOfStepsCOONoS.Details].Type =
        data[EKeysOfStepsCOONoS.Type].Type;
      const sendSteps = pickBy(data, function (value, key) {
        if (keysOfSendStepsCOONoS.includes(key as EKeysOfStepsCOONoS)) {
          return { [key]: value };
        }
      });

      for (const [key, value] of Object.entries(sendSteps)) {
        let dataStep = Array.isArray(value) ? [...value] : { ...value };
        if (dataStep && dataStep?._option) {
          delete dataStep._option;
        }
        if (key === EKeysOfStepsCOONoS.Workflow) {
          secondaryWorkflowUtilProcessing.processData(
            value,
            workflowHeader,
            workflowDetail
          );
        } else {
          workflowDetail[key] = dataStep;
        }
      }
      return {
        WorkflowHeader: workflowHeader,
        WorkflowDetail: workflowDetail,
      };
    };

    /**
     * Processes the workflow
     * @param props - Properties required for the workflow processing.
     * @returns A promise that resolves when the processing is complete or rejects if an error occurs.
     */
    const handleProcessWorkflow = async (
      props: INewProcessWorkflow<DTO_Workflow_ChangeOfOwnership_NoticeOfSale>
    ) => {
      const {
        payload,
        actionSuccess,
        actionFail,
        defaultFailedMessage,
        setLoading,
        modeProcess,
        actionClose,
      } = props;

      const response = await postProcessChangeOfOwnershipNoS(
        modeProcess,
        payload
      );

      setLoading();
      if (isSuccessResponse(response) && response?.data?.IsSuccess) {
        actionSuccess(response?.data);
      } else {
        if (typeof actionFail === "function") actionFail(response);
        notificationFormStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
      }
      if (typeof actionClose === "function") actionClose();
    };
    //#endregion COMMON FUNCTIONS

    //#region SAVE
    /**
     * Handles the click event of the next button.
     * @param data - Workflow data.
     * @returns A promise that resolves with a boolean indicating the success of the saving operation.
     */
    const handleNextButton = async (data: any) => {
      return await handleSaveProcess(processData(data));
    };

    /**
     * Handles saving process.
     * @param payload - Workflow data.
     * @param isCloseDialog - Optional. Indicates whether to close the dialog after saving. Default is false.
     * @param isRefreshWorkflowData - Optional. Indicates whether to refresh the workflow data after saving. Default is false.
     * @returns A promise that resolves with a boolean indicating the success of the saving operation.
     */
    const handleSaveProcess = async (
      payload: DTO_Workflow_ChangeOfOwnership_NoticeOfSale,
      isCloseDialog: boolean = false,
      isRefreshWorkflowData: boolean = false
    ): Promise<boolean> => {
      setIsLoadingProcess(WorkflowProcessMode.Save);
      const response = await postProcessChangeOfOwnershipNoS(
        WorkflowProcessMode.Save,
        payload
      );

      //set default notification
      const defaultSuccessMessage = `${defaultTitle} was saved successfully.`;
      const defaultFailedMessage = `${defaultTitle} could not be saved.`;

      if (isSuccessResponse(response) && response?.data?.IsSuccess) {
        if (isCloseDialog) {
          onClose();
          pushNotification({
            title:
              response?.data?.SuccessMessage ??
              response?.data?.Notification ??
              defaultSuccessMessage,
            type: "success",
          });
        }
        setWorkflowInitialData(response?.data?.ReturnData);
        // TODO: Show notification after reloading the step -> enhance later
        if (isRefreshWorkflowData) {
          getWorkflowData().then(() => {
            notificationFormStepRef?.current
              ?.getNotificationFormStep()
              ?.current?.pushNotification({
                title:
                  response?.data?.SuccessMessage ??
                  response?.data?.Notification ??
                  defaultSuccessMessage,
                type: "success",
              });
          });
        }
        setIsLoadingProcess(undefined);
        return true;
      } else {
        const showNotification = () => {
          notificationFormStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title:
                (isRefreshWorkflowData
                  ? head(response?.data?.Errors)
                  : response.data?.ErrorMessage) ?? defaultFailedMessage,
              type: "error",
              autoClose: false,
            });
        };
        if (isRefreshWorkflowData) {
          getWorkflowData().then(showNotification);
        } else {
          showNotification();
        }
        return false;
      }
    };
    //#endregion SAVE

    //#region PARK
    /**
     * Handles parking process.
     * @param payload - Workflow data.
     */
    const handleParkProcess = async (
      payload: DTO_Workflow_ChangeOfOwnership_NoticeOfSale,
      mode?: EListSubmitButton
    ) => {
      //set loading button and dialog
      setIsLoadingProcess(WorkflowProcessMode.Park);
      mode === EListSubmitButton.ConfirmCloseYes && setIsLoadingClose(true);
      const actionCloseRetainDialog = () => {
        setIsLoadingClose(false);
        setDataForCloseDialog();
      };
      //props send to process workflow
      const parkProps: INewProcessWorkflow<DTO_Workflow_ChangeOfOwnership_NoticeOfSale> =
        {
          payload: payload,
          actionSuccess: (e) => {
            eventEmitter.emit(CCGridEventType.RefreshOData);
            onClose();
            pushNotification({
              title:
                e?.SuccessMessage ??
                e?.Notification ??
                `${defaultTitle} was parked successfully.`,
              type: "success",
            });
          },
          setLoading: () => setIsLoadingProcess(undefined),
          actionClose: () => {
            mode === EListSubmitButton.ConfirmCloseYes &&
              actionCloseRetainDialog();
          },
          defaultFailedMessage: `${defaultTitle} could not be parked.`,
          modeProcess: WorkflowProcessMode.Park,
        };

      //calling api process workflow
      await handleProcessWorkflow(parkProps);
    };
    //#endregion PARK

    //#region CANCEL
    /**
     * Handles cancelling process.
     * @param payload - Workflow data.
     */
    const handleCancelButton = (
      data: DTO_Workflow_ChangeOfOwnership_NoticeOfSale
    ) => {
      if (isFromActionList || !isFirstSave) {
        setDataForCancelDialog({
          cancelAPI: postProcessChangeOfOwnershipNoS,
          dataCancel: data,
          actionSuccess: () => {
            clearSelectedItems(); //After Cancel, the status of the record will be changed to "Cancelled" -> clear selected items
            eventEmitter.emit(CCGridEventType.RefreshOData);
          },
          defaultSuccessMessage: `${defaultTitle} was cancelled successfully.`,
          defaultErrorMessage: `${defaultTitle} could not be cancelled.`,
        });
      } else {
        onClose();
      }
    };
    //#endregion CANCEL

    //#region RETAIN
    /**
     * Handles closing workflow dialog.
     * @param payload - The render properties associated with the dialog.
     */
    const handleCloseDialog = (renderProps: ICCFormStepRender) => {
      if (!isFromActionList && !isFirstSave) {
        setDataForCloseDialog({
          closeCallback: renderProps.submitButton.onClick,
        });
      } else if (
        isIncompleteMode &&
        dataFromActionList?.Workflow_Status_Name === EWorkflowStatus.Park
      ) {
        onClose();
      } else if (
        dataFromActionList?.Workflow_Status_Name ===
          EWorkflowStatus.Incomplete &&
        !isFirstSave
      ) {
        const newEvent = {
          currentTarget: { id: EListSubmitButton.Close },
        };
        renderProps.submitButton.onClick(newEvent);
      } else {
        onClose();
      }
    };
    //#endregion RETAIN

    //#region FINISH
    /**
     * Handles the confirmation of finishing the workflow process.
     * @param payload - Workflow data.
     */
    const handleConfirmFinishProcess = (
      payload: DTO_Workflow_ChangeOfOwnership_NoticeOfSale
    ) => {
      const dataProcessed = processData(payload);
      const finishCallback = function async() {
        return handleFinishProcess(dataProcessed);
      };
      setDataForFinishDialog({
        finishCallback,
        confirmMessage: `The ${defaultTitle} will be created based on the information provided. Are you sure you want to submit?`,
      });
    };

    /**
     * Handles finishing the workflow process.
     * @param payload - Workflow data.`
     */
    const handleFinishProcess = async (
      payload: DTO_Workflow_ChangeOfOwnership_NoticeOfSale
    ) => {
      //props send to process workflow
      const finishProps: INewProcessWorkflow<DTO_Workflow_ChangeOfOwnership_NoticeOfSale> =
        {
          payload: payload,
          actionSuccess: (e) => {
            eventEmitter.emit(CCGridEventType.RefreshOData);
            clearSelectedItems(); //After finish, the status of the record will be changed to "Completed" -> clear selected items
            if (!isFromActionList) {
              //Refresh document and comment accordion
              eventEmitter.emit(PropertyDocumentEventType.RefreshData);
              eventEmitter.emit(CCGridEventType.RefreshOData, {
                gridIds: [COMMENT_ACCORDION_GRID_ID],
              });
            }
            onClose();
            pushNotification({
              title:
                e?.SuccessMessage ??
                e?.Notification ??
                `${defaultTitle} was completed successfully.`,
              type: "success",
            });
          },
          setLoading: () => {
            setIsLoadingProcess(undefined);
          },
          defaultFailedMessage: `${defaultTitle} could not be completed.`,
          modeProcess: WorkflowProcessMode.Finish,
        };

      await handleProcessWorkflow(finishProps);
    };
    //#endregion FINISH

    //#region APPROVE
    /**
     * Handles the approval of the workflow process.
     * @param payload - Workflow data.
     */
    const handleApproveProcess = async (
      payload: DTO_Workflow_ChangeOfOwnership_NoticeOfSale
    ) => {
      setIsLoadingProcess(WorkflowProcessMode.Approve);
      //props send to process workflow
      const approveProps: INewProcessWorkflow<DTO_Workflow_ChangeOfOwnership_NoticeOfSale> =
        {
          payload: payload,
          actionSuccess: (e) => {
            onClose();
            pushNotification({
              title:
                e?.SuccessMessage ??
                e?.Notification ??
                `${defaultTitle} was approved successfully.`,
              type: "success",
            });
          },
          setLoading: () => {
            setIsLoadingProcess(undefined);
          },
          defaultFailedMessage: `${defaultTitle} could not be approved.`,
          modeProcess: WorkflowProcessMode.Approve,
        };

      //calling api process workflow
      await handleProcessWorkflow(approveProps);
    };
    //#endregion APPROVE

    //#region REJECT
    /**
     * Handles the rejection of a workflow process.
     * @param data - Workflow data.
     */
    const handleRejectButton = (
      data: DTO_Workflow_ChangeOfOwnership_NoticeOfSale
    ) => {
      if (isFromActionList || !isFirstSave) {
        setDataForRejectDialog({
          rejectCallback: postProcessChangeOfOwnershipNoS,
          dataReject: data,
          defaultSuccessMessage: `${defaultTitle} was rejected successfully.`,
          defaultErrorMessage: `${defaultTitle} could not be rejected.`,
        });
      } else {
        onClose();
      }
    };
    //#endregion REJECT

    //#region SEND BACK
    /**
     * Handles confirming the sending back of the workflow process.
     * @param payload - Workflow data.
     */
    const handleConfirmSendBackProcess = (
      payload: DTO_Workflow_ChangeOfOwnership_NoticeOfSale
    ) => {
      setDataForSendBackDialog({
        sendBackCallback: postProcessChangeOfOwnershipNoS,
        dataSendBack: payload,
        defaultSuccessMessage: `${defaultTitle} was sent back successfully.`,
        defaultErrorMessage: `${defaultTitle} could not be sent back.`,
      });
    };
    //#endregion SEND BACK

    //#region REALLOCATE
    /**
     * Handles confirming the reallocation of the workflow process.
     * @param payload - Workflow data.
     */
    const handleConfirmReallocateProcess = (
      payload: DTO_Workflow_ChangeOfOwnership_NoticeOfSale
    ) => {
      setDataForReallocateDialog({
        reallocateCallback: postProcessChangeOfOwnershipNoS,
        dataReallocate: payload,
        defaultSuccessMessage: `${defaultTitle} approved successfully.`,
        defaultErrorMessage: `${defaultTitle} could not be approved.`,
      });
    };
    //#endregion REALLOCATE

    return (
      <CCFormStep
        ref={notificationFormStepRef}
        listButtonId={listSubmitButton}
        onSubmit={handleSubmit}
        onValueChange={(values) => {
          setSelectedTypeId(values[EKeysOfStepsCOONoS.Type]?.Type);
        }}
        initialValues={initialValues}
        initialSteps={steps}
        saveOnNextStep={isSaveOnNextStep ? handleNextButton : undefined}
        renderForm={(renderProps: ICCFormStepRender) => (
          <CCDialog
            maxWidth="60%"
            titleHeader={titleHeader}
            disabled={!isNil(isLoadingProcess)}
            badge={statusBadge}
            onClose={() => handleCloseDialog(renderProps)}
            bodyElement={renderProps.children}
            footerElement={
              <>
                <div className={"cc-dialog-footer-actions-right"}>
                  {isShowParkButton(isFromActionList, isIncompleteMode) && (
                    <Button
                      iconClass={
                        isLoadingProcess === WorkflowProcessMode.Park
                          ? "fas fa-spinner fa-spin"
                          : ""
                      }
                      className={"cc-dialog-button"}
                      id={EListSubmitButton.Park}
                      onClick={renderProps.submitButton.onClick}
                      disabled={
                        renderProps.nextButton.disabled ||
                        isLoadingProcess === WorkflowProcessMode.Park
                      }
                    >
                      Park
                    </Button>
                  )}
                  {isShowCancelWorkflowButton && (
                    <Button
                      className={"cc-dialog-button"}
                      disabled={!isNil(isLoadingProcess)}
                      id={EListSubmitButton.Cancel}
                      onClick={renderProps.submitButton.onClick}
                    >
                      Cancel
                    </Button>
                  )}

                  {isToBeApprovalMode &&
                    workflowInitialData?.WorkflowHeader?.OfficerCanApprove && (
                      <>
                        <Button
                          themeColor="primary"
                          id={EListSubmitButton.SendBack}
                          disabled={renderProps.nextButton.disabled}
                          className={"cc-dialog-button"}
                          onClick={renderProps.submitButton.onClick}
                        >
                          Send Back
                        </Button>
                        <Button
                          themeColor="primary"
                          id={EListSubmitButton.Reallocate}
                          disabled={renderProps.nextButton.disabled}
                          className={"cc-dialog-button"}
                          onClick={renderProps.submitButton.onClick}
                        >
                          Reallocate
                        </Button>
                        <Button
                          themeColor="primary"
                          id={EListSubmitButton.Approve}
                          disabled={
                            isLoadingProcess === WorkflowProcessMode.Approve ||
                            renderProps.nextButton.disabled
                          }
                          className={"cc-dialog-button"}
                          onClick={renderProps.submitButton.onClick}
                          iconClass={
                            isLoadingProcess === WorkflowProcessMode.Approve
                              ? "fas fa-spinner fa-spin"
                              : ""
                          }
                        >
                          Approve
                        </Button>
                        <Button
                          themeColor="primary"
                          id={EListSubmitButton.Reject}
                          disabled={renderProps.nextButton.disabled}
                          className={"cc-dialog-button"}
                          onClick={renderProps.submitButton.onClick}
                        >
                          Reject
                        </Button>
                      </>
                    )}
                  {!renderProps.prevButton.disabled && (
                    <Button
                      className={"cc-dialog-button"}
                      themeColor="primary"
                      onClick={renderProps.prevButton.onClick}
                    >
                      Previous
                    </Button>
                  )}
                  {isToBeApprovalMode || isReadOnly ? (
                    !renderProps.isLastStep && (
                      <Button
                        themeColor="primary"
                        id="cc-next-step-button"
                        disabled={
                          isLoadingProcess === WorkflowProcessMode.Save ||
                          renderProps.nextButton.disabled
                        }
                        className={"cc-dialog-button"}
                        iconClass={
                          isLoadingProcess === WorkflowProcessMode.Save
                            ? "fas fa-spinner fa-spin"
                            : ""
                        }
                        onClick={renderProps.nextButton.onClick}
                      >
                        {isLoadingProcess === WorkflowProcessMode.Save
                          ? "Saving"
                          : renderProps.nextButton.label}
                      </Button>
                    )
                  ) : (
                    <Button
                      themeColor="primary"
                      id={renderProps.nextButton.idButton}
                      disabled={
                        isLoadingProcess === WorkflowProcessMode.Save ||
                        renderProps.nextButton.disabled
                      }
                      iconClass={
                        isLoadingProcess === WorkflowProcessMode.Save
                          ? "fas fa-spinner fa-spin"
                          : ""
                      }
                      className={"cc-dialog-button"}
                      onClick={renderProps.nextButton.onClick}
                    >
                      {isLoadingProcess === WorkflowProcessMode.Save
                        ? "Saving"
                        : renderProps.nextButton.label}
                    </Button>
                  )}
                </div>
              </>
            }
          />
        )}
      />
    );
  }
);
