import { ITabProps, TabTable } from "@app/core/tab-table/_index";
import { TabTableType } from "@app/core/tab-table/model";
import { FormAction } from "@app/products/crms/model";
import { DirectDebitStatus } from "@app/products/direct-debit/model";
import { DDSessionLineBySessionNumber } from "@app/products/direct-debit/sessions/[id]/components/accordions/transfer/_index";
import { DDSessionChangeStatusButton } from "@app/products/direct-debit/sessions/[id]/components/buttons/change-status/_index";
import { DDExportBankFileButton } from "@app/products/direct-debit/sessions/[id]/components/buttons/export-bank-file/_index";
import { DDSendToReceiptingButton } from "@app/products/direct-debit/sessions/[id]/components/buttons/send-to-receipting/_index";
import { DirectDebitSessionForm } from "@app/products/direct-debit/sessions/[id]/components/general/_index";
import { SessionsHistoryTab } from "@app/products/direct-debit/sessions/[id]/components/slidebar/history/_index";
import { DirectDebitSessionHeader } from "@app/products/direct-debit/sessions/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { useGlobalStore } from "@common/stores/global/store";
import { formatDateByKendo } from "@common/utils/formatting";
import { FormTitle } from "@components/cc-form/title/_index";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { YieldNotificationPortal } from "@components/cc-notification-portal/_index";
import { TypePlaceId } from "@components/cc-notification-portal/constant";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import { observer } from "mobx-react-lite";
import React, { useCallback, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

interface IExistManageDirectDebitSessionProps {
  checkPermissions: (
    formAction?: FormAction | FormAction[] | undefined
  ) => boolean;
}
export const ExistManageDirectDebitSession = observer(
  ({ checkPermissions }: IExistManageDirectDebitSessionProps) => {
    const { currentUserInfo } = useGlobalStore();
    const { dataForms } = useFlexibleFormStore();
    const { id } = useParams<{ id: string }>();
    const directDebitSession =
      dataForms?.GeneralForm as DirectDebitSessionHeader;

    const [showSlideBar, setShowSlideBar] = useState(true);

    //#region HOOK
    const location = useLocation();
    //#endregion HOOK

    const bookmarkList: IBookmarkItem[] = [
      {
        LinkText: `Direct Debit Session - ${directDebitSession?.Session_Number}`,
        Text: `Direct Debit Session`,
        LinkUrl: location.pathname,
        User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
        ProductType_ENUM: PRODUCT_TYPE.DirectDebit,
        RecordType_ENUM: RECORDTYPE.Receipting_DirectDebitSessionHeader,
        Record_ID: +directDebitSession?.Session_Number,
      },
    ];

    const listPanelBar: ITabProps[] = [
      {
        title: "Transfers",
        component: (
          <DDSessionLineBySessionNumber
            sessionID={directDebitSession?.Session_ID}
          />
        ),
        tabType: TabTableType.DDTransfers,
      },
    ];

    const sessionStatus = useCallback(
      (status: DirectDebitStatus) => {
        return directDebitSession?.Status_Type_ENUM === status;
      },
      [directDebitSession?.Status_Type_ENUM]
    );

    const isCancelOrComplete = useMemo(() => {
      return (
        sessionStatus(DirectDebitStatus.Cancelled) ||
        sessionStatus(DirectDebitStatus.Completed)
      );
    }, [sessionStatus]);

    const isStatusOpen = useMemo(() => {
      return sessionStatus(DirectDebitStatus.Open);
    }, [sessionStatus]);

    const isDisabledCancelButton = useMemo(() => {
      return (
        isCancelOrComplete ||
        sessionStatus(DirectDebitStatus.ExportBankFile) ||
        sessionStatus(DirectDebitStatus.SendToReceipting) ||
        !checkPermissions(FormAction.DIRECTDEBIT_FORM_SETCANCEL)
      );
    }, [isCancelOrComplete, checkPermissions, sessionStatus]);

    const isDisabledExportBankFileButton = useMemo(() => {
      return (
        isCancelOrComplete ||
        sessionStatus(DirectDebitStatus.SendToReceipting) ||
        sessionStatus(DirectDebitStatus.ExportBankFile) ||
        !checkPermissions(FormAction.DIRECTDEBIT_FORM_SESSION_EXPORTBANKFILE)
      );
    }, [isCancelOrComplete, checkPermissions, sessionStatus]);

    const isDisabledSendToReceiptingButton = useMemo(() => {
      return (
        isCancelOrComplete ||
        isStatusOpen ||
        sessionStatus(DirectDebitStatus.SendToReceipting) ||
        !checkPermissions(FormAction.DIRECTDEBIT_FORM_SESSION_SENDTORECEIPTING)
      );
    }, [isCancelOrComplete, isStatusOpen, checkPermissions, sessionStatus]);

    const isDisabledCompleteButton = useMemo(() => {
      return (
        isCancelOrComplete ||
        isStatusOpen ||
        sessionStatus(DirectDebitStatus.ExportBankFile) ||
        !checkPermissions(FormAction.DIRECTDEBIT_FORM_SETCOMPLETE)
      );
    }, [isCancelOrComplete, isStatusOpen, checkPermissions, sessionStatus]);

    return (
      <>
        <FormTitle
          title={`${directDebitSession?.Session_Number} - ${formatDateByKendo(
            directDebitSession?.Transfer_Date,
            DATE_FORMAT.DATE_CONTROL
          )}`}
          badge={directDebitSession?.Status_Type_Name}
        />
        <CCManagePageActionBar
          leftActions={[
            <DDSessionChangeStatusButton
              statusUpdate={DirectDebitStatus.Cancelled}
              isDisabled={isDisabledCancelButton}
            />,
            <DDExportBankFileButton
              isDisabled={isDisabledExportBankFileButton}
            />,
            <DDSendToReceiptingButton
              isDisabled={isDisabledSendToReceiptingButton}
            />,
            <DDSessionChangeStatusButton
              isDisabled={isDisabledCompleteButton}
            />,
          ]}
          rightActions={[
            <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            <CCNavButton
              title={"Slide Bar"}
              iconClass="fal fa-info-circle"
              isActive={showSlideBar}
              onClick={() => {
                setShowSlideBar(!showSlideBar);
              }}
            />,
          ]}
        />
        <div className="cc-manage-container">
          <div className="cc-manage-left">
            <YieldNotificationPortal placeId={TypePlaceId.Main} />
            <div className="cc-manage-form-body">
              {directDebitSession && <DirectDebitSessionForm />}
              <TabTable
                id={parseInt(id)}
                recordType={RECORDTYPE.Receipting_DirectDebitSessionHeader}
                initialPanels={listPanelBar}
              />
            </div>
          </div>
          {showSlideBar && (
            <div className="cc-manage-right">
              <ReferenceSideBar
                components={[
                  {
                    title: "History",
                    component: <SessionsHistoryTab />,
                  },
                ]}
              />
            </div>
          )}
        </div>
      </>
    );
  }
);
