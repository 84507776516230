import { APIResponseError } from "@common/apis/model";
import { APIResponseStatus } from "@common/constants/response-status";
import {
  LOAD_FAILED_SUB_TITLE,
  LOAD_FAILED_TITLE,
} from "@components/cc-load-failed/config";
import { UISecurityConstants } from "@components/cc-load-failed/constant";
import { CCResult, ICCResultProps } from "@components/cc-result/_index";
import { Button } from "@progress/kendo-react-buttons";
import React, { useEffect, useMemo, useRef } from "react";
import "./_index.scss";
const defaultResult = {
  title: LOAD_FAILED_TITLE.DEFAULT,
  subTitle: LOAD_FAILED_SUB_TITLE.DEFAULT,
};
export interface ICCLoadFailedProps extends ICCResultProps {
  onReload?: () => void;
  responseError?: APIResponseError;
  style?: object;
  isLoadingButton?: boolean;
}
export const CCLoadFailed = ({
  status = "error",
  responseError,
  onReload,
  style,
  isLoadingButton,
}: ICCLoadFailedProps) => {
  const loadFailedRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const gridElement = loadFailedRef?.current?.closest(".cc-grid");
    if (gridElement?.clientWidth && loadFailedRef.current) {
      loadFailedRef.current.style.maxWidth = gridElement?.clientWidth + "px";
    }
  }, [loadFailedRef]);

  const resultData = useMemo<{
    title: string;
    subTitle: string | string[];
  }>(() => {
    if (!responseError) return defaultResult;
    const { status, error } = responseError;
    //#region TODO: will remove if have solution change status code from server (OData API) ========/
    if (
      error === UISecurityConstants.ACCESS_RIGHT_DENIED ||
      error?.[0] === UISecurityConstants.ACCESS_RIGHT_DENIED
    ) {
      return {
        title: "",
        subTitle: error ?? defaultResult.subTitle,
      };
    }
    //#endregion TODO: will remove if have solution change status code from server (OData API) =====/
    if (status === APIResponseStatus.BAD_REQUEST)
      return {
        ...defaultResult,
        subTitle: error ?? defaultResult.subTitle,
      };
    if (status === APIResponseStatus.TIME_OUT)
      return {
        ...defaultResult,
        title: LOAD_FAILED_TITLE.REQUEST_TIME_OUT,
        subTitle: error ?? defaultResult.subTitle,
      };
    if (status === APIResponseStatus.NON_AUTHORITATIVE)
      return {
        title: "",
        subTitle: error ?? defaultResult.subTitle,
      };
    if (status === APIResponseStatus.FORBIDDEN)
      return {
        title: "",
        subTitle: error ?? LOAD_FAILED_SUB_TITLE.SECURITY_DENIED,
      };
    if (status >= APIResponseStatus.INTERNAL_SERVER_ERROR)
      return {
        ...defaultResult,
        title: LOAD_FAILED_TITLE.SERVER_ERROR,
        subTitle: error ?? defaultResult.subTitle,
      };
    return {
      ...defaultResult,
      subTitle: error ?? defaultResult.subTitle,
    };
  }, [responseError]);

  return (
    <CCResult
      ref={loadFailedRef}
      style={style}
      status={status}
      title={resultData.title}
      subTitle={resultData.subTitle}
      className="cc-load-failed"
      extra={
        <Button
          themeColor="primary"
          onClick={onReload}
          iconClass={isLoadingButton ? "fas fa-spinner fa-spin" : ""}
          disabled={isLoadingButton}
        >
          Reload
        </Button>
      }
    />
  );
};
