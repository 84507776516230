export enum PaymentFrequency {
  Unspecified = 0,
  OneOff = 1,
  Weekly = 5,
  Fortnightly = 10,
  Monthly = 15,
  Quarterly = 20,
  HalfYearly = 25,
  Yearly = 30,
}

export enum PaymentFrequencyFilter {
  "One Off" = "One Off",
  "Weekly" = "Weekly",
  "Fortnightly" = "Fortnightly",
  "Monthly" = "Monthly",
  "Quarterly" = "Quarterly",
  "Half Yearly" = "Half Yearly",
  "Yearly" = "Yearly",
}

export enum IntegrationModuleType {
  Unspecified = 0,
  CommunityFinance = 5,
  PropertyAssessment = 10,
  PropertyRegister = 15,
}
export enum IntegrationModuleTypeFilter {
  "Community Finance" = "Community Finance",
  "Property Assessment" = "Property Assessment",
  "Property Register" = "Property Register",
}
export enum DirectDebitStatus {
  Unspecified = 0,
  Open = 1,
  PendingApproval = 5,
  Released = 10,
  ExportBankFile = 15,
  SendToReceipting = 20,
  RejectionsReceived = 25,
  Completed = 30,
  Cancelled = 35,
}
export enum DirectDebitStatusFilter {
  "Open" = "Open",
  "Pending Approval" = "Pending Approval",
  "Released" = "Released",
  "Export Bank File" = "Export Bank File",
  "Send To Receipting" = "Send To Receipting",
  "Rejections Received" = "Rejections Received",
  "Completed" = "Completed",
  "Cancelled" = "Cancelled",
}

export enum DirectDebitMenu {
  Unspecified = 0,
  Accounts = 1,
  AccountsByAccount = 2,
  AccountsByContact = 3,
  AccountsByModuleType = 4,
  AccountsByStatus = 5,
  AccountsVIP = 6,
  Authorisations = 10,
  AuthorisationsByAuthorisation = 11,
  AuthorisationsByContact = 12,
  AuthorisationsVIP = 13,
  Sessions = 20,
  SessionsByStatus = 21,
  SessionsByDate = 22,
  SessionsNotCompleted = 23,
  SystemsAdmin = 30,
  ModuleItemTypes = 31,
  Groups = 32,
  StatusCodes = 33,
  BankAccounts = 34,
}

export enum DBRowStateWithoutArchive {
  Inactive = 0,
  Active = 1,
}
