import { postDirectDebitAccount } from "@app/products/direct-debit/accounts/[id]/api";
import { DIRECT_DEBIT_ACCOUNT_GET_ALIAS } from "@app/products/direct-debit/accounts/[id]/constant";
import { checkIsInactiveDirectDebitAccount } from "@app/products/direct-debit/accounts/[id]/util";
import { DirectDebitAccount } from "@app/products/direct-debit/accounts/model";
import {
  fetchApiByAlias,
  useFlexibleFetchData,
} from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import { observer } from "mobx-react-lite";
import React from "react";

interface ISaveButtonDirectDebitAccountProps {
  isDisabled?: boolean;
}
export const SaveButtonDirectDebitAccount = observer(
  ({ isDisabled = false }: ISaveButtonDirectDebitAccountProps) => {
    //#region STORE ========/
    const { submitFormGetData, isLoadingForm, setIsLoadingForm } =
      useFlexibleFormStore();

    //#endregion STORE =====/

    //#region HOOK ========/
    const { pushNotificationPortal } = useNotificationPortalStore();
    const { cancelToken } = useCancelRequest();
    //#endregion HOOK =====/

    const slider = useFlexibleFetchData({
      slides: [
        {
          fetch: ({ initialData }) => {
            const directDebitAccount: DirectDebitAccount = initialData;
            return postDirectDebitAccount(directDebitAccount, cancelToken());
          },
          handleSuccess: async () => {
            await fetchApiByAlias(DIRECT_DEBIT_ACCOUNT_GET_ALIAS);
            pushNotificationPortal({
              title: "Direct debit account saved successfully.",
              type: "success",
            });

            return false;
          },
          handleError: (error) => {
            const { errorFromApi, initialData } = error;
            pushNotificationPortal({
              title: initialData?.errorMsg,
              type: "error",
              description: errorFromApi.error,
              autoClose: false,
            });
          },
        },
      ],
    });

    const handleClickButton = async () => {
      const directDebitAccount: DirectDebitAccount = await submitFormGetData(
        "GeneralForm"
      );

      if (directDebitAccount) {
        setIsLoadingForm(true);
        await slider.fetchApi({
          initialData: directDebitAccount,
        });
        setIsLoadingForm(false);
      }
    };

    return (
      <CCNavButton
        title="Save"
        onClick={handleClickButton}
        disabled={
          isLoadingForm || checkIsInactiveDirectDebitAccount() || isDisabled
        }
      />
    );
  }
);
