import { AddAttachmentButton } from "@app/core/attachment/components/buttons/add-attachment/_index";
import { Comments } from "@app/core/comments/_index";
import { AddCommentButton } from "@app/core/comments/components/buttons/add-comment/_index";
import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { AdditionalContactButton } from "@app/core/contacts/components/buttons/additional-contact/_index";
import { ContactAccordion } from "@app/core/contacts/components/contact-accordion/_index";
import { Documents } from "@app/core/documents/_index";
import { AddDocumentButton } from "@app/core/documents/components/buttons/add-document/_index";
import Inspections from "@app/core/inspections/inspections-accordion/_index";
import { AddInspectionsWithParentButton } from "@app/core/inspections/inspections-button/_index";
import {
  MAILMERGEDATASET,
  MailMerge_SelectMergeDocument,
} from "@app/core/new-mail-merge/dialogs/model";
import { AddNoticeWithParentButton } from "@app/core/notices/notices-button/_index";
import { RelatedAccordion } from "@app/core/related/related-accordion/_index";
import { ICoreRelatedParams } from "@app/core/related/related-accordion/model";
import { AddRelatedButton } from "@app/core/related/related-button/_index";
import { ITabProps, TabTable } from "@app/core/tab-table/_index";
import { TabTableType } from "@app/core/tab-table/model";
import { Svc_ViewOnMap } from "@app/core/view-on-map/components/buttons/view-map-by-gis/model";
import { getSetupAddDocument } from "@app/products/crms/[id]/api";
import { ChangeVIPButton } from "@app/products/crms/[id]/components/forms/components/action-bar/buttons/change-vip/_index";
import { ReopenButton } from "@app/products/crms/[id]/components/forms/components/action-bar/buttons/reopen/_index";
import { CRMSEventForm } from "@app/products/crms/[id]/components/forms/components/child-screens/general/_index";
import { CRMSEventContactTab } from "@app/products/crms/[id]/components/forms/components/reference-sidebar/contact/_index";
import { CRMSEventDetailsTab } from "@app/products/crms/[id]/components/forms/components/reference-sidebar/details/_index";
import { CRMSGuidanceTab } from "@app/products/crms/[id]/components/forms/components/reference-sidebar/guidance/_index";
import { CRMSHistoryTab } from "@app/products/crms/[id]/components/forms/components/reference-sidebar/history/_index";
import {
  CS_EventStatus,
  ElementDisplayStatus,
} from "@app/products/crms/[id]/model";
import { useCRMSEventStore } from "@app/products/crms/[id]/store";
import { ProductType } from "@app/products/crms/components/dialogs/acknowledge-events/model";
import { CRMS_PRODUCT_NAME } from "@app/products/crms/config";
import { FormAction, FormIdentifier } from "@app/products/crms/model";
import { PPRActions } from "@app/products/town-planning/ppr/[id]/components/child-screens/actions/_index";
import { AddActionWithParentButton } from "@app/products/town-planning/ppr/components/action-bar/buttons/add/action/_index";
import { isSuccessResponse } from "@common/apis/util";
import { ACCESSRIGHTS } from "@common/constants/enumerations";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { useIsNew } from "@common/hooks/useIsNew";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { ActionSubmitActions } from "@common/pages/actions/model";
import { getCheckPermission } from "@common/pages/settings/api";
import { FormActionCheckPermission } from "@common/pages/settings/constant";
import { useCommonCoreStore } from "@common/stores/core/store";
import { useGlobalStore } from "@common/stores/global/store";
import {
  getBoolValueSetting,
  getStringValueSetting,
} from "@common/stores/products/util";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import {
  appNotificationStore,
  useCCAppNotificationStore,
} from "@components/cc-app-notification/store";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCNotFoundPage } from "@components/cc-not-found-page/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import { MapTab } from "@components/cc-reference-sidebar/components/Map/_index";
import Loading from "@components/loading/Loading";
import { isEmpty } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

export const ExistedCRMSEvent = observer(() => {
  const { state } = useLocation<{ isFromSimilarEvent: boolean }>();
  const { settings } = useCommonCoreStore();
  const { pushNotification } = useCCAppNotificationStore();
  const { checkPermissions, isLoadingPermission } = useCheckPermissions({
    formIdentifier: FormIdentifier.CUSTOMERSERVICE_Form_Event,
    productType: PRODUCT_TYPE_NUMBER.CustomerService,
  });

  const enableQuickCloseSetting = getBoolValueSetting(
    settings[
      ECorporateSettingsField.CUSTOMERSERVICE_ServiceStandard_EnableQuickClose
    ]
  );
  const showReopenEventButton = getBoolValueSetting(
    settings[ECorporateSettingsField.CUSTOMERSERVICE_Event_AllowReopen]
  );
  const allowRecategoriseSetting = getBoolValueSetting(
    settings[ECorporateSettingsField.CUSTOMERSERVICE_Event_AllowRecategorise]
  );
  const allowReallocateOfficer = getBoolValueSetting(
    settings[ECorporateSettingsField.CUSTOMERSERVICE_AllowReallocateOfficer]
  );

  const councillorRequestsShowActions = getBoolValueSetting(
    settings[
      ECorporateSettingsField.CUSTOMERSERVICE_CouncillorRequests_ShowActions
    ]
  );
  const councillorRequestsShowRecords = getBoolValueSetting(
    settings[
      ECorporateSettingsField.CUSTOMERSERVICE_CouncillorRequests_ShowRecords
    ]
  );
  const councillorRequestsShowComments = getBoolValueSetting(
    settings[
      ECorporateSettingsField.CUSTOMERSERVICE_CouncillorRequests_ShowComments
    ]
  );

  const [isHideReopenButton, setIsHideReopenButton] = useState(false);

  const actionOfficerLabel = getStringValueSetting(
    settings[ECorporateSettingsField.CUSTOMERSERVICE_Common_ActionOfficerLabel]
  );

  const checkPermissionReopenButton = async () => {
    const response = await getCheckPermission(
      FormActionCheckPermission.CRMS_CLOSEDEVENT_REOPENEVENT,
      PRODUCT_TYPE_NUMBER.CustomerService
    );

    setIsHideReopenButton(response?.data?.ErrorStatus === "HasErrors");
  };

  const { id } = useParams<{ id: string }>();
  const isNew = useIsNew();
  const managePageUrl = window.location.pathname;
  const [showSlideBar, setShowSlideBar] = useState(true);
  const {
    loadCRMSEvent,
    crmsEvent,
    responseLoadError,
    isLoading,
    isLoadingSendForAction,
    crmsEventId,
    onSubmit,
    formValid,
    generateBadge,
    uiControl,
    notAllowAccessMsg,
    accessRights,
    isHasVip,
    eventLitBannerMiddle,
  } = useCRMSEventStore();

  const { currentUserInfo } = useGlobalStore();

  const bookmarkList: IBookmarkItem[] = [
    {
      Text: `${getDisplayTextWithDashes([crmsEvent?.Event_Description])}`,
      LinkUrl: managePageUrl,
      LinkText: `${CRMS_PRODUCT_NAME}${
        crmsEvent?.Event_ReferenceNo ? ` - ${crmsEvent?.Event_ReferenceNo}` : ""
      }`,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.CustomerService,
      RecordType_ENUM: RECORDTYPE.CUSTOMERSERVICE_Event,
      Record_ID: crmsEventId ? +crmsEventId : 0,
    },
  ];

  const relatedAccordionParams = useMemo(() => {
    if (crmsEventId) {
      const data: ICoreRelatedParams = {
        RecordType: RECORDTYPE.CUSTOMERSERVICE_Event,
        RecordID: crmsEventId,
        InvoiceNumber: null,
        AssessmentNumberKey: null,
        AddressLine1: null,
        Suburb: null,
        PersonKey: null,
        WasteWaterSchemeKey: null,
        AncestorRecordType: null,
        AncestorRecordId: null,
      };

      if (crmsEvent?.IssueLocation?.LocationAddress) {
        data.AssessmentNumberKey =
          crmsEvent.IssueLocation.LocationAddress?.AssessmentNo;
        data.AddressLine1 =
          crmsEvent.IssueLocation.LocationAddress?.Formatted_AddressLine1;
        data.Suburb = crmsEvent.IssueLocation.LocationAddress?.Suburb;
      }

      if (crmsEvent?.ContactRelation && crmsEvent.ContactRelation?.Contact) {
        data.PersonKey = crmsEvent.ContactRelation.Contact?.Contact_ID;
      }

      return data;
    }
    return undefined;
  }, [
    crmsEvent?.ContactRelation,
    crmsEvent?.IssueLocation.LocationAddress,
    crmsEventId,
  ]);

  const isResendForAction = useMemo(() => {
    return (
      crmsEvent?.EventStatus_ENUM === CS_EventStatus.Pending ||
      crmsEvent?.EventStatus_ENUM === CS_EventStatus.Open ||
      crmsEvent?.EventStatus_ENUM === CS_EventStatus.Scheduled
    );
  }, [crmsEvent?.EventStatus_ENUM]);

  const isInspectionDisabled = useMemo(() => {
    return (
      (!crmsEvent?.ServiceStandard?.Flag_Inspection ?? false) ||
      !checkPermissions(FormAction.CORE_TOOLBAR_ADD_INSPECTION)
    );
  }, [checkPermissions, crmsEvent?.ServiceStandard?.Flag_Inspection]);

  const isNoticeDisabled = useMemo(() => {
    return (
      (!crmsEvent?.ServiceStandard?.IsNotice ?? false) ||
      !checkPermissions(FormAction.CORE_TOOLBAR_ADD_NOTICE)
    );
  }, [checkPermissions, crmsEvent?.ServiceStandard?.IsNotice]);

  const isSaveDisabled = useMemo(() => {
    return (
      crmsEvent?.EventStatus_ENUM === CS_EventStatus.Closed ||
      accessRights === ACCESSRIGHTS.CanRead ||
      !checkPermissions(FormAction.CORE_ALLOW_EDIT)
    );
  }, [accessRights, checkPermissions, crmsEvent?.EventStatus_ENUM]);

  const isSendForActionDisabled = useMemo(() => {
    return (
      !formValid ||
      !(
        crmsEvent?.EventStatus_ENUM === CS_EventStatus.Draft ||
        crmsEvent?.EventStatus_ENUM === CS_EventStatus.Pending ||
        crmsEvent?.EventStatus_ENUM === CS_EventStatus.Open ||
        (crmsEvent?.EventStatus_ENUM === CS_EventStatus.Scheduled &&
          crmsEvent?.ServiceStandard_Id !== 0)
      ) ||
      accessRights === ACCESSRIGHTS.CanRead ||
      (isResendForAction
        ? !checkPermissions(FormAction.CRMS_RESEND_EVENT)
        : false) ||
      !!crmsEvent?.ServiceStandard?.Flag_System
    );
  }, [
    accessRights,
    checkPermissions,
    crmsEvent?.EventStatus_ENUM,
    crmsEvent?.ServiceStandard_Id,
    formValid,
    isResendForAction,
    crmsEvent?.ServiceStandard?.Flag_System,
  ]);

  const isCloseEventDisable = useMemo(() => {
    return (
      !formValid ||
      crmsEvent?.EventStatus_ENUM === CS_EventStatus.Closed ||
      (crmsEvent?.EventStatus_ENUM === CS_EventStatus.Draft &&
        !(
          enableQuickCloseSetting &&
          crmsEvent?.ServiceStandard &&
          crmsEvent?.ServiceStandard?.EnableQuickClose
        )) ||
      accessRights === ACCESSRIGHTS.CanRead ||
      !checkPermissions(FormAction.CRMS_CLOSE_EVENT)
    );
  }, [
    accessRights,
    checkPermissions,
    crmsEvent?.EventStatus_ENUM,
    crmsEvent?.ServiceStandard,
    enableQuickCloseSetting,
    formValid,
  ]);

  const isChangeVIPDisabled = useMemo(() => {
    return (
      accessRights === ACCESSRIGHTS.CanRead ||
      crmsEvent?.EventStatus_ENUM === CS_EventStatus.Closed
    );
  }, [accessRights, crmsEvent?.EventStatus_ENUM]);

  const isDisabledRecategorise = useMemo(() => {
    if (
      !allowRecategoriseSetting &&
      crmsEvent?.EventStatus_ENUM !== CS_EventStatus.Draft
    ) {
      return true;
    } else if (
      crmsEvent?.ServiceStandard &&
      !crmsEvent?.ServiceStandard?.AllowRecategorise &&
      crmsEvent?.EventStatus_ENUM !== CS_EventStatus.Draft
    ) {
      return true;
    }

    if (accessRights === ACCESSRIGHTS.CanRead) return true;

    return false;
  }, [
    accessRights,
    allowRecategoriseSetting,
    crmsEvent?.EventStatus_ENUM,
    crmsEvent?.ServiceStandard,
  ]);

  const isDisabledChangeActionOfficer = useMemo(() => {
    if (
      !allowReallocateOfficer &&
      crmsEvent?.EventStatus_ENUM !== CS_EventStatus.Draft
    ) {
      return true;
    } else if (
      crmsEvent?.ServiceStandard &&
      !crmsEvent?.ServiceStandard?.AllowReallocateOfficer &&
      crmsEvent?.EventStatus_ENUM !== CS_EventStatus.Draft
    ) {
      return true;
    }

    if (accessRights === ACCESSRIGHTS.CanRead) return true;

    return false;
  }, [
    accessRights,
    allowReallocateOfficer,
    crmsEvent?.EventStatus_ENUM,
    crmsEvent?.ServiceStandard,
  ]);

  const listPanelBar: ITabProps[] = [
    {
      title: "Actions",
      tabType: TabTableType.Actions,
      isVisible: !(
        currentUserInfo?.FlagCouncillor && !councillorRequestsShowActions
      ),
      component: (
        <PPRActions
          id={parseInt(id)}
          recordType={RECORDTYPE.CUSTOMERSERVICE_Event}
        />
      ),
    },
    {
      title: "Contacts",
      tabType: TabTableType.Contacts,
      component: (
        <ContactAccordion
          id={parseInt(id)}
          recordType={RECORDTYPE.CUSTOMERSERVICE_Event}
        />
      ),
    },
    {
      title: "Comments",
      tabType: TabTableType.Comments,
      isVisible: !(
        currentUserInfo?.FlagCouncillor && !councillorRequestsShowComments
      ),
      component: (
        <Comments
          id={parseInt(id)}
          recordType={RECORDTYPE.CUSTOMERSERVICE_Event}
        />
      ),
    },
    {
      title: "Documents",
      tabType: TabTableType.Documents,
      isVisible: !(
        currentUserInfo?.FlagCouncillor && !councillorRequestsShowRecords
      ),
      component: (
        <Documents
          id={parseInt(id)}
          recordType={RECORDTYPE.CUSTOMERSERVICE_Event}
          isUseSharepoint
          isAddToRecords
          isExistedFolderRecords={!isEmpty(crmsEvent?.EventRecords_FileNumber)}
        />
      ),
    },
    {
      title: "Inspections",
      tabType: TabTableType.Inspections,
      component: (
        <Inspections
          id={parseInt(id)}
          recordType={RECORDTYPE.CUSTOMERSERVICE_Event}
        />
      ),
      isVisible: !!crmsEvent?.ServiceStandard?.Flag_Inspection,
    },
    {
      title: "Related",
      tabType: TabTableType.Related,
      component: <RelatedAccordion relatedParam={relatedAccordionParams} />,
    },
  ];

  const handleClickActionAddDocument = async (
    mailMerge: MailMerge_SelectMergeDocument
  ): Promise<MailMerge_SelectMergeDocument | undefined> => {
    if (crmsEvent?.ServiceStandard_Id) {
      let newMailMergeData: MailMerge_SelectMergeDocument | undefined =
        undefined;
      const response = await getSetupAddDocument(crmsEvent?.ServiceStandard_Id);
      if (isSuccessResponse(response) && response?.data) {
        newMailMergeData = {
          ...mailMerge,
          DocumentIDs: response.data?.ReturnObj,
        };
        return newMailMergeData;
      } else {
        pushNotification({
          title: "Setup add document failed.",
          type: "error",
          autoClose: false,
        });
      }
    }
    return undefined;
  };

  useEffect(() => {
    checkPermissionReopenButton();
    loadCRMSEvent(parseInt(id), isNew);
    if (state?.isFromSimilarEvent) {
      appNotificationStore.clearNotifications();
      window.history.replaceState({}, "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isNew]);

  if (notAllowAccessMsg) {
    return <CCNotFoundPage title={notAllowAccessMsg} subTitle="" />;
  }
  return (
    <>
      <Loading
        isLoading={isLoading || isLoadingSendForAction || isLoadingPermission}
        isFullScreen
      />
      <FormNavigation title="CRMS Event" />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadCRMSEvent(parseInt(id), isNew)}
        />
      ) : (
        <>
          <FormTitle
            title={
              crmsEvent
                ? `${crmsEvent?.Event_ReferenceNo} - ${crmsEvent.ServiceStandard?.Category}`
                : ""
            }
            badge={crmsEvent && generateBadge(eventLitBannerMiddle?.Value)}
          />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Workflow"}
                type="sub-workflow"
                className="cc-workflow-button"
                disabled={accessRights === ACCESSRIGHTS.CanRead}
                isDefaultActive
              >
                <CCNavButton
                  title={
                    isResendForAction ? "Resend for action" : "Send for action"
                  }
                  onClick={onSubmit}
                  name={ActionSubmitActions.SendForAction}
                  disabled={isSendForActionDisabled}
                />
                <CCNavButton
                  title={"Close event"}
                  onClick={onSubmit}
                  name={ActionSubmitActions.CloseEvent}
                  disabled={isCloseEventDisable}
                />
                <ReopenButton
                  isHidden={
                    crmsEvent?.EventStatus_ENUM !== CS_EventStatus.Closed ||
                    uiControl?.BtnReopenEvent?.DisplayStatus ===
                      ElementDisplayStatus?.Hidden ||
                    !showReopenEventButton
                  }
                  disabled={
                    isHideReopenButton || accessRights === ACCESSRIGHTS.CanRead
                  }
                />
                <CCNavButton
                  title={"Change category"}
                  onClick={onSubmit}
                  name={ActionSubmitActions.ChangeCategory}
                  disabled={isDisabledRecategorise}
                />

                <CCNavButton
                  title={"Reschedule"}
                  onClick={onSubmit}
                  name={ActionSubmitActions.RescheduleAction}
                  disabled={
                    crmsEvent?.EventStatus_ENUM === CS_EventStatus.Closed ||
                    uiControl?.DivReschedule.DisplayStatus !==
                      ElementDisplayStatus.Visible ||
                    accessRights === ACCESSRIGHTS.CanRead
                  }
                />

                <CCNavButton
                  disabled={isDisabledChangeActionOfficer}
                  title={
                    actionOfficerLabel
                      ? `Change ${actionOfficerLabel}`
                      : "Change action officer"
                  }
                  onClick={onSubmit}
                  name={ActionSubmitActions.ChangeActionOfficer}
                />

                <ChangeVIPButton
                  disabled={isChangeVIPDisabled}
                  hidden={!isHasVip}
                />

                <CCNavButton
                  title="Special interest"
                  onClick={onSubmit}
                  name={ActionSubmitActions.SpecialInterest}
                  disabled={
                    crmsEvent?.EventStatus_ENUM === CS_EventStatus.Closed ||
                    accessRights === ACCESSRIGHTS.CanRead
                  }
                />
                <CCNavButton
                  title="Security"
                  onClick={onSubmit}
                  name={ActionSubmitActions.AccessControl}
                  disabled={
                    crmsEvent?.EventStatus_ENUM === CS_EventStatus.Closed ||
                    accessRights === ACCESSRIGHTS.CanRead
                  }
                />
              </CCNavButton>,
              <CCNavButton
                title={"Save"}
                onClick={onSubmit}
                name={ActionSubmitActions.Save}
                disabled={isSaveDisabled}
              />,
              <CCNavButton
                title={"Add"}
                type="sub"
                disabled={accessRights === ACCESSRIGHTS.CanRead}
              >
                <AddActionWithParentButton
                  id={parseInt(id)}
                  recordType={RECORDTYPE.CUSTOMERSERVICE_Event}
                  bubbleUps={crmsEvent?.BubbleUp}
                  disabled={
                    !checkPermissions(FormAction.CORE_TOOLBAR_ADD_ACTION)
                  }
                />
                <AdditionalContactButton
                  id={parseInt(id)}
                  recordType={RECORDTYPE.CUSTOMERSERVICE_Event}
                  disabled={
                    !checkPermissions(
                      FormAction.CORE_TOOLBAR_ADD_ADDITIONALCONTACT
                    )
                  }
                />
                <AddAttachmentButton
                  id={parseInt(id)}
                  recordType={RECORDTYPE.CUSTOMERSERVICE_Event}
                  bubbleUps={crmsEvent?.BubbleUp}
                  disabled={
                    !checkPermissions(FormAction.CORE_TOOLBAR_ADD_ATTACHMENT)
                  }
                  mustRefreshCallback={(
                    mustRefreshNotification: IAppNotificationItemAddProps
                  ) => {
                    if (crmsEvent && !crmsEvent.EventRecords_FileNumber) {
                      loadCRMSEvent(
                        parseInt(id),
                        isNew,
                        undefined,
                        mustRefreshNotification
                      );
                    }
                  }}
                />
                <AddCommentButton
                  id={parseInt(id)}
                  recordType={RECORDTYPE.CUSTOMERSERVICE_Event}
                  isDisabled={
                    !checkPermissions(FormAction.CORE_TOOLBAR_ADD_COMMENT)
                  }
                />
                <CommunicationButtonListView
                  productType={PRODUCT_TYPE_NUMBER.CustomerService}
                  recordType={RECORDTYPE.CUSTOMERSERVICE_Event}
                  recordId={parseInt(id)}
                  isDisabled={
                    !checkPermissions(FormAction.CORE_TOOLBAR_ADD_COMMUNICATION)
                  }
                />
                <AddDocumentButton
                  dataSetFilter={MAILMERGEDATASET?.CUSTOMERSERVICE_Event}
                  recordType={RECORDTYPE.CUSTOMERSERVICE_Event}
                  productType={PRODUCT_TYPE_NUMBER.CustomerService}
                  id={parseInt(id)}
                  bubbleUps={crmsEvent?.BubbleUp}
                  handleClickAction={handleClickActionAddDocument}
                  isDisabled={
                    !checkPermissions(FormAction.CORE_TOOLBAR_ADD_DOCUMENT)
                  }
                />
                {/* TODO: Implement later */}
                {/* <CCNavButton title={"Councillor comment"} /> */}
                <AddInspectionsWithParentButton
                  id={parseInt(id)}
                  recordType={RECORDTYPE.CUSTOMERSERVICE_Event}
                  bubbleUps={crmsEvent?.BubbleUp}
                  isDisabled={isInspectionDisabled}
                  productType={PRODUCT_TYPE_NUMBER.CustomerService}
                />
                <AddNoticeWithParentButton
                  id={parseInt(id)}
                  recordType={RECORDTYPE.CUSTOMERSERVICE_Event}
                  bubbleUps={crmsEvent?.BubbleUp}
                  isDisabled={isNoticeDisabled}
                  productType={PRODUCT_TYPE_NUMBER.CustomerService}
                />
                <AddRelatedButton
                  id={crmsEventId}
                  recordType={RECORDTYPE.CUSTOMERSERVICE_Event}
                />
              </CCNavButton>,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,

              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />
          <div className="cc-manage-container">
            <div className="cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {crmsEvent && (
                  <>
                    <CRMSEventForm />
                    <TabTable
                      id={parseInt(id)}
                      recordType={RECORDTYPE.CUSTOMERSERVICE_Event}
                      initialPanels={listPanelBar}
                      options={{
                        invoiceNumber: relatedAccordionParams?.InvoiceNumber,
                        propertyAssessmentNoKey:
                          relatedAccordionParams?.AssessmentNumberKey,
                        addressLine1: relatedAccordionParams?.AddressLine1,
                        suburb: relatedAccordionParams?.Suburb,
                        personKey: relatedAccordionParams?.PersonKey,
                        schemeKey: relatedAccordionParams?.WasteWaterSchemeKey,
                        refNoKey: relatedAccordionParams?.CsmRefNoKey
                          ? relatedAccordionParams.CsmRefNoKey.toString()
                          : undefined,
                        ancestorRecordId:
                          relatedAccordionParams?.AncestorRecordId,
                        ancestorRecordType:
                          relatedAccordionParams?.AncestorRecordType,
                      }}
                    />
                  </>
                )}
              </div>
            </div>
            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar
                  components={[
                    { title: "Details", component: <CRMSEventDetailsTab /> },
                    {
                      title: "Map",
                      component: (
                        <MapTab
                          address={
                            crmsEvent?.IssueLocation?.LocationAddress
                              ?.Formatted_SingleLine
                          }
                          zoom={18}
                          objViewOnMapRequest={
                            {
                              ReturnControllName: "GISKey",
                              GISKey:
                                crmsEvent?.IssueLocation?.LocationAddress
                                  ?.AssessmentNo ?? "",
                              ObjAddress:
                                crmsEvent?.IssueLocation?.LocationAddress,
                              Module: "",
                              Layer: "",
                              ProductType: ProductType.CustomerService,
                            } as Svc_ViewOnMap
                          }
                          isInManagePage
                          isDisabled={
                            !checkPermissions(FormAction.CRMS_TOOLS_VIEWONMAP)
                          }
                        />
                      ),
                    },
                    {
                      title: "Contacts",
                      component: <CRMSEventContactTab />,
                    },
                    {
                      title: "Guidance",
                      component: <CRMSGuidanceTab isManagePage />,
                      isVisible: !!crmsEvent?.BulletinBoardID,
                    },
                    {
                      title: "History",
                      component: <CRMSHistoryTab />,
                    },
                  ]}
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});
