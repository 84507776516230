import { DTO_ChangeOfOwnership_NoticeOfSale_TitleOwners } from "@app/products/property/changes-of-ownership/notice-of-sales/components/form-steps/update-notice-of-sale/components/form-elements/property/title-detail/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const getChangeOfOwnershipLRSTitleOwners = async (
  lrsTitleId: number
): Promise<APIResponse<DTO_ChangeOfOwnership_NoticeOfSale_TitleOwners>> => {
  try {
    return await CoreAPIService.getClient().get<DTO_ChangeOfOwnership_NoticeOfSale_TitleOwners>(
      `api/property/int/changeofownershipnos/lrstitleowners/${lrsTitleId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
