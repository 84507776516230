import { getDDAccountItemTypeLOVs } from "@app/products/direct-debit/accounts/[id]/api";
import { getKeyValueFromLOVs } from "@app/products/direct-debit/accounts/[id]/util";
import {
  DirectDebitAccount,
  DirectDebitAccountLOVs,
  IntegrationModuleItemType,
} from "@app/products/direct-debit/accounts/model";
import { IntegrationModuleType } from "@app/products/direct-debit/model";
import { APIResponse } from "@common/apis/model";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import {
  fetchApiByAlias,
  useFlexibleFetchData,
} from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

interface IDDAccountDetailsSectionProps {
  formRenderProps: FormRenderProps;
  isDisabled?: boolean;
}

const nameOf = nameOfFactory<DirectDebitAccount>();

export const DDAccountDetailsSection = observer(
  ({ formRenderProps, isDisabled }: IDDAccountDetailsSectionProps) => {
    const { valueGetter, onChange } = formRenderProps;
    const getValue = (nameOfField: keyof DirectDebitAccount) =>
      valueGetter(nameOf(nameOfField));
    const onChangeField = (nameOfField: string, value: any) => {
      onChange(nameOf(nameOfField as keyof DirectDebitAccount), {
        value: value,
      });
    };

    const { cancelToken } = useCancelRequest();
    const { dataForms, setInitialDataForms } = useFlexibleFormStore();
    const ddAccountsLOVs = dataForms?.GeneralFormLovs as DirectDebitAccountLOVs;

    const getItemTypeLOVsSlider = useFlexibleFetchData({
      alias: "getItemTypeLOVsSlider",
      slides: [
        {
          fetch: () => {
            return getDDAccountItemTypeLOVs(
              cancelToken(),
              getValue("Integration_Module_Type_ENUM") as IntegrationModuleType
            );
          },
          handleSuccess: ({ dataFromApi }) => {
            const response: APIResponse<IntegrationModuleItemType[]> =
              dataFromApi;
            const ddAccountFormData = response.data;
            //#region save data to store ========/
            setInitialDataForms({
              GeneralFormLovs: {
                ...dataForms?.GeneralFormLovs,
                ItemTypes: getKeyValueFromLOVs(ddAccountFormData),
              },
            });
            //#endregion save data to store =====/
          },
        },
      ],
    });

    const handleModuleTypeChange = (event: ComboBoxChangeEvent) => {
      onChangeField("Integration_Module_Type_ENUM", event.target.value?.Key);
      onChangeField("Integration_Module_Type_Name", event.target.value?.Value);
      onChangeField("Item_Type", undefined);
      fetchApiByAlias("getItemTypeLOVsSlider");
    };

    const handleItemTypeChange = (event: ComboBoxChangeEvent) => {
      onChangeField("Item_Type", event.target.value?.Key);
    };

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title="Module type" isMandatory />
            <Field
              name={nameOf("Integration_Module_Type_ENUM")}
              dataItemKey="Key"
              textField="Value"
              data={ddAccountsLOVs?.IntegrationModuleTypes ?? []}
              value={getDropdownValue(
                getValue("Integration_Module_Type_ENUM"),
                ddAccountsLOVs?.IntegrationModuleTypes ?? []
              )}
              onChange={handleModuleTypeChange}
              component={CCSearchComboBox}
              disabled
            />
          </div>
          <div className="cc-field">
            <CCLabel title={"Account reference"} />
            <Field
              name={nameOf("Account_Reference")}
              placeholder={"Account Reference"}
              component={CCInput}
              readOnly
            />
          </div>
          <div className="cc-field">
            <CCLabel title={"Item type"} isMandatory />
            <Field
              name={nameOf("Item_Type")}
              dataItemKey="Key"
              textField="Value"
              data={ddAccountsLOVs?.ItemTypes ?? []}
              value={getDropdownValue(
                getValue("Item_Type"),
                ddAccountsLOVs?.ItemTypes ?? []
              )}
              onChange={handleItemTypeChange}
              component={CCSearchComboBox}
              disabled
              isLoading={getItemTypeLOVsSlider.isFetching}
            />
          </div>
        </div>

        {getValue("Integration_Module_Type_ENUM") ===
          IntegrationModuleType.PropertyRegister && (
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title={"Register Id"} />
              <Field
                name={nameOf("Register_ID")}
                placeholder={"Register Id"}
                component={CCNumericTextBox}
                format={NUMBER_FORMAT.NUMBER2}
                readOnly
              />
            </div>
            <div className="cc-field">
              <CCLabel title={"Register account Id"} />
              <Field
                name={nameOf("Register_Account_ID")}
                placeholder={"Register account Id"}
                component={CCNumericTextBox}
                format={NUMBER_FORMAT.NUMBER2}
                readOnly
              />
            </div>
            <div className="cc-field">
              <CCLabel title={"Property address"} />
              <Field
                name={nameOf("Address_2")}
                placeholder={"Property address"}
                component={CCInput}
                readOnly
              />
            </div>
          </div>
        )}

        {getValue("Integration_Module_Type_ENUM") ===
          IntegrationModuleType.PropertyAssessment && (
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title={"Assessment Id"} />
              <Field
                name={nameOf("AssessmentID")}
                placeholder={"Assessment Id"}
                component={CCNumericTextBox}
                readOnly
              />
            </div>
            <div className="cc-field">
              <CCLabel title={"Assessment number"} />
              <Field
                name={nameOf("Assessment_Number")}
                placeholder={"Assessment number"}
                component={CCInput}
                readOnly
              />
            </div>
            <div className="cc-field">
              <CCLabel title={"Assessment reference"} />
              <Field
                name={nameOf("Assessment_Reference")}
                placeholder={"Assessment reference"}
                component={CCInput}
                readOnly
              />
            </div>
            <div className="cc-field">
              <CCLabel title={"Property address"} />
              <Field
                name={nameOf("Property_Address")}
                placeholder={"Property address"}
                component={CCInput}
                readOnly
              />
            </div>
          </div>
        )}

        {getValue("Integration_Module_Type_ENUM") ===
          IntegrationModuleType.CommunityFinance && (
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title={"Finance customer number"} />
              <Field
                name={nameOf("Finance_Customer_No")}
                placeholder={"Finance customer number"}
                component={CCInput}
                readOnly
              />
            </div>
            <div className="cc-field">
              <CCLabel title={"Finance invoice number"} />
              <Field
                name={nameOf("Finance_Invoice_No")}
                placeholder={"Finance invoice number"}
                component={CCInput}
                readOnly
              />
            </div>
          </div>
        )}
      </section>
    );
  }
);
