import { EPrivilegesWorkflow } from "@app/products/property/model";
import {
  ICategoryNameSetting,
  mappingCategoryNamePropertySetting,
  SettingsMap,
  TCategoryNamePropertySettingKey,
} from "@app/products/property/system-admin/settings/model";
import { listSettingsCategoryNamePropertyPath } from "@app/products/property/system-admin/settings/route";
import { convertSettings } from "@app/products/property/system-admin/settings/util";
import { APIResponseError } from "@common/apis/model";
import { getSettings } from "@common/apis/setting";
import { isSuccessResponse } from "@common/apis/util";
import { AusState } from "@common/constants/enumAusState";
import { ICCRoute } from "@common/constants/ICCRoute";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { globalStoreInstance } from "@common/stores/global/store";
import { getActiveProducts } from "@common/stores/products/api";
import { customFields } from "@common/stores/products/config";
import { eMenuAction, eProductType } from "@common/stores/products/enum";
import {
  CCLabelNameDTO,
  CCMenuNodeDTO,
  CCPrivilege,
  CustomFieldAttribute,
  CustomFieldConfig,
  CustomFieldConfigAllProducts,
  ECustomColNameMap,
  ICCMenuItem,
  IFlatCCMenuItem,
  OrganisationMode,
  ProductListingDTO,
} from "@common/stores/products/model";
import { commonTownPlanningStore } from "@common/stores/products/town-planning/store";
import {
  convertProductsToMenu,
  convertRouteToMenu,
  flatMenu,
} from "@common/stores/products/util";
import { ActiveProduct } from "@components/layout/model";
import { cloneDeep, isNil, isNull } from "lodash";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
const MODE: "api" | "route" = "api"; //@TODO:Remove when Vijay give full menu

class CommonProductsStore {
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;

  private _productRoute?: ICCRoute = undefined;
  private _coreRoute?: ICCRoute = undefined;
  private _privileges?: CCPrivilege[] = [];

  private _activeProducts: ProductListingDTO[] = []; //The Active Product list from API
  private _activeProductsMap: { [key in eProductType]?: ProductListingDTO } =
    {}; //The Mapping of Active Product from API
  private _productsMenu: ICCMenuItem[] = []; //Menu for Active Products
  private _coreMenu: ICCMenuItem[] = []; //Menu for Core product

  private _allMenu: ICCMenuItem[] = [];
  private _flatMenu: IFlatCCMenuItem[] = []; //Flat list or ICCMenuItem but not children
  private _settings: SettingsMap = {}; //Product settings
  private _isLoadingSetting: boolean = false; //Products settings loading
  private _responseLoadSettingError?: APIResponseError = undefined;

  private _listCategoryPropertySettingVisible: ICategoryNameSetting[] = [];
  private _customFieldsConfigAllProducts?: CustomFieldConfigAllProducts =
    undefined;
  private _productName: string = "";

  constructor() {
    makeAutoObservable(this);
  }

  get productName() {
    return toJS(this._productName);
  }
  setProductName = (productName: string) => {
    runInAction(() => {
      this._productName = productName;
    });
  };

  get listCategoryPropertySettingVisible() {
    return toJS(this._listCategoryPropertySettingVisible);
  }
  private setListCategoryPropertySettingVisible = (
    list: ICategoryNameSetting[]
  ) => {
    runInAction(() => {
      this._listCategoryPropertySettingVisible = list;
    });
  };

  get settings() {
    return toJS(this._settings);
  }
  setSettings = (settings: SettingsMap) => {
    runInAction(() => {
      this._settings = settings;
    });
  };

  get isLoadingSetting() {
    return this._isLoadingSetting;
  }
  setIsLoadingSetting = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoadingSetting = isLoading;
    });
  };

  get responseLoadSettingError() {
    return toJS(this._responseLoadSettingError);
  }
  setResponseLoadSettingError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadSettingError = responseLoadError;
    });
  };

  get productRoute() {
    return this._productRoute;
  }
  setProductRoute = (productRoute?: ICCRoute) => {
    runInAction(() => {
      this._productRoute = productRoute;
    });
  };

  get coreRoute() {
    return this._coreRoute;
  }
  setCoreRoute = (coreRoute?: ICCRoute) => {
    runInAction(() => {
      this._coreRoute = coreRoute;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get activeProductsMap() {
    return toJS(this._activeProductsMap);
  }
  setActiveProductsMap = (_activeProductsMap: {
    [key in eProductType]?: ProductListingDTO;
  }) => {
    runInAction(() => {
      this._activeProductsMap = _activeProductsMap;
    });
  };

  get activeProducts() {
    return toJS(this._activeProducts);
  }
  setActiveProducts = (activeProducts: ProductListingDTO[]) => {
    runInAction(() => {
      this._activeProducts = activeProducts;
    });
  };

  get productsMenu() {
    return toJS(this._productsMenu);
  }
  setProductsMenu = (productsMenu: ICCMenuItem[]) => {
    runInAction(() => {
      this._productsMenu = productsMenu;
    });
  };

  get coreMenu() {
    return toJS(this._coreMenu);
  }
  setCoreMenu = (coreMenu: ICCMenuItem[]) => {
    runInAction(() => {
      this._coreMenu = coreMenu;
    });
  };

  get allMenu() {
    return toJS(this._allMenu);
  }
  setAllMenu = (allMenu: ICCMenuItem[]) => {
    runInAction(() => {
      this._allMenu = allMenu;
    });
  };

  get flatMenu() {
    return toJS(this._flatMenu);
  }
  setFlatMenu = (flatMenu: IFlatCCMenuItem[]) => {
    runInAction(() => {
      this._flatMenu = flatMenu;
    });
  };

  get privileges() {
    return toJS(this._privileges);
  }
  setPrivileges = (privileges: CCPrivilege[]) => {
    runInAction(() => {
      this._privileges = privileges;
    });
  };

  setCustomFieldConfigAllProducts = (
    customFieldConfigAllProducts?: CustomFieldConfigAllProducts
  ) => {
    runInAction(() => {
      this._customFieldsConfigAllProducts = customFieldConfigAllProducts;
    });
  };

  //Action
  isPrivilegeWorkflow = (enumPrivilege: EPrivilegesWorkflow): boolean => {
    // allow user to open all workflow if no privileges (undefined) return (admin privileges)
    if (!this.privileges) return true;
    const valuePrivilege = this.privileges?.some(
      (item: CCPrivilege) => enumPrivilege === item.Id
    );
    return valuePrivilege;
  };

  isPrivilegeDialog = (enumPrivilege: EPrivilegesWorkflow) => {
    // allow user to open all workflow if no privileges (undefined)  return (admin privileges)
    if (!this.privileges) return true;
    const valuePrivilege = this.privileges?.some(
      (item: CCPrivilege) => item.Id === enumPrivilege
    );
    return valuePrivilege;
  };
  /**
   * Check current State in Community Property
   * @param state
   * @returns boolean
   */
  currentStateProperty = (state: AusState): boolean => {
    if (this.activeProducts) {
      const stateCurrent =
        this.activeProductsMap[eProductType.CommunityProperty]?.Organisations
          ?.Organisation?.State ?? "";
      return state === stateCurrent;
    }
    return false;
  };

  currentOrganisationMode = (state: OrganisationMode): boolean => {
    if (this.activeProducts) {
      const stateCurrent =
        this.activeProductsMap[eProductType.CommunityProperty]?.Organisations
          ?.Organisation?.OrganisationMode ?? "";
      return state === stateCurrent;
    }
    return false;
  };

  currentFormTitle = (formId: number): string | undefined => {
    if (this.activeProducts) {
      return (
        this.activeProductsMap[
          eProductType.CommunityProperty
        ]?.FormConfig?.Forms?.find((item) => item.Form_Id === formId)
          ?.Form_Title ?? undefined
      );
    }
    return undefined;
  };

  /**
   * Gets labels based on custom fields and product type.
   * @param {ECustomColNameMap<T> | ECustomColNameMap<T>[]} customFields - The custom field or an array of custom fields.
   * @param {eProductType} productTypeEnum - The product type for which labels are requested.
   * @returns {string | string[]} The label or array of labels corresponding to the provided custom fields and product type.
   * If the customFieldsConfig is not available, it returns the customFields array or a single customField as a string.
   */
  getLabel = <T extends eProductType>(
    customFields: ECustomColNameMap<T> | ECustomColNameMap<T>[],
    productTypeEnum: eProductType
  ): string | string[] => {
    const customFieldsConfig = this._customFieldsConfigAllProducts;
    if (customFieldsConfig?.[productTypeEnum]) {
      const getSingleLabel = (customField: ECustomColNameMap<T>) => {
        const customValue =
          customFieldsConfig[productTypeEnum]?.[customField]?.CustomValue;
        const defaultValue =
          customFieldsConfig[productTypeEnum]?.[customField]?.DefaultValue;
        return customValue
          ? customValue ?? +customField + ""
          : defaultValue + "";
      };

      if (Array.isArray(customFields)) {
        return customFields.map(getSingleLabel);
      } else {
        return getSingleLabel(customFields);
      }
    }
    return Array.isArray(customFields) ? customFields : customFields + "";
  };

  resetStore = () => {
    runInAction(() => {
      this._isLoading = false;
      this._responseLoadError = undefined;
      this._activeProducts = [];
      this._activeProductsMap = {};
      this._productsMenu = [];
      this._coreMenu = [];
      this._allMenu = [];
      this._flatMenu = [];
      this._customFieldsConfigAllProducts = undefined;
    });
  };

  isProductActive = (path: string): IFlatCCMenuItem | undefined => {
    if (this.responseLoadError) return undefined;
    const paramIndex = path.search(":"); //Check is manage page
    if (paramIndex > 0) {
      path = path.slice(0, paramIndex - 1); //Remove param in the path
    }
    return this.flatMenu.find((item) => item.path === path);
  };

  getMenuName = (path: string) => {
    const findMenu = this.flatMenu.find((item) => item.path === path);
    if (!findMenu) return undefined;
    return findMenu.name;
  };

  loadSettings = async (settingEnums: ECorporateSettingsField[]) => {
    this.setIsLoadingSetting(true);
    const response = await getSettings(settingEnums);
    if (isSuccessResponse(response)) {
      this.setSettings(convertSettings(response.data ?? []));
      this.setResponseLoadSettingError(undefined);
    } else {
      this.setSettings({});
      this.setResponseLoadSettingError({
        status: response.status,
        error: response.error,
      });
    }
    this.setIsLoadingSetting(false);
  };

  loadProducts = async () => {
    this.setIsLoading(true);
    const productsResponse = await getActiveProducts();
    let isActivePPR = false;
    if (
      isSuccessResponse(productsResponse) &&
      !isNil(productsResponse.data) &&
      Array.isArray(productsResponse.data) &&
      productsResponse.data?.length > 0
    ) {
      isActivePPR = productsResponse.data?.some(
        (item: ProductListingDTO) =>
          item.ProductType_ENUM === eProductType.TownPlanning
      );
      let newProductRouteChildren = this.productRoute?.children ?? [];
      if (isActivePPR) {
        /* Start Products Setting */
        const townPlanning = await commonTownPlanningStore.loadSettingCorePPR();
        if (townPlanning && townPlanning.route)
          newProductRouteChildren.push(townPlanning.route);
        /* End Products Setting */
      }
      //Set active to global store
      //@TODO: remove
      let activeProductsSorted = productsResponse.data.sort(
        (item1: ProductListingDTO, item2: ProductListingDTO) => {
          if (isNull(item1.Menu_Sort_Order) || isNull(item2.Menu_Sort_Order))
            return 1;
          return item1.Menu_Sort_Order - item2.Menu_Sort_Order;
        }
      );
      productsResponse.data?.forEach((item: ProductListingDTO) => {
        if (item.ProductType_ENUM === eProductType.CommunityProperty) {
          this.setPrivileges(item?.Privileges?.Privileges);
        }
      });
      globalStoreInstance.setActiveProducts(
        (activeProductsSorted as ActiveProduct[]) ?? []
      );

      let mapping: { [key in eProductType]?: ProductListingDTO } = {};
      activeProductsSorted.forEach((product, index) => {
        mapping[product.ProductType_ENUM] = product;

        //@TODO:Remove when Vijay give full menu
        if (MODE === "route") {
          activeProductsSorted[index].Menu = undefined;
        }
      });

      const hiddenMenu: ICCMenuItem[] = [];

      const newProductsMenu = convertProductsToMenu(
        activeProductsSorted,
        newProductRouteChildren,
        hiddenMenu
      );
      let newCoreMenu: ICCMenuItem[] = [];
      this.coreRoute?.children?.forEach((child) => {
        const newChild = convertRouteToMenu(child);
        if (newChild) newCoreMenu.push(newChild);
      });

      const newAllMenu = [...newProductsMenu, ...newCoreMenu];

      //this code using for Community Property Setting
      const menuProperty: CCMenuNodeDTO[] =
        activeProductsSorted?.find(
          (product: ProductListingDTO) =>
            product.ProductType_ENUM === eProductType.CommunityProperty
        )?.Menu?.Nodes ?? [];
      if (menuProperty) {
        //get List child of Setting
        const menuPropertySetting =
          menuProperty
            ?.find(
              (subItem) =>
                subItem.MenuActionEnumeration_Id ===
                eMenuAction.SettingsCategory_Folder
            )
            ?.ChildNodes?.find(
              (setting) =>
                setting.MenuActionEnumeration_Id ===
                eMenuAction.SettingsCategory_Menu
            )?.ChildNodes ?? [];
        //get List child of Setting Category Name
        const listEnumCategoryActive =
          menuPropertySetting
            ?.find(
              (subItem) =>
                subItem.MenuActionEnumeration_Id ===
                eMenuAction.SettingsCategory_Category
            )
            ?.ChildNodes?.map((item) => item.MenuActionEnumeration_Id) ?? [];
        const cloneListCategorySetting = cloneDeep(
          listSettingsCategoryNamePropertyPath
        );

        const categoryNameSettingActive = cloneListCategorySetting
          ?.filter((item) =>
            listEnumCategoryActive.includes(item?.enum as eMenuAction)
          )
          ?.map((category) => {
            return {
              CategoryName:
                mappingCategoryNamePropertySetting[
                  category?.enum as TCategoryNamePropertySettingKey
                ],
              ProductType: PRODUCT_TYPE.CommunityProperty,
            };
          });

        this.setListCategoryPropertySettingVisible(
          categoryNameSettingActive as ICategoryNameSetting[]
        );
      }

      this.setActiveProducts(activeProductsSorted);
      this.setActiveProductsMap(mapping);
      this.overrideConfigFromResponse(mapping);
      this.setProductsMenu(newProductsMenu);
      this.setCoreMenu(newCoreMenu);
      this.setAllMenu(newAllMenu);

      this.setFlatMenu(
        flatMenu({
          name: "root",
          path: "/",
          children: [...newAllMenu, ...hiddenMenu],
        })
      );
      this.setResponseLoadError(undefined);
      this.setIsLoading(false);
      return;
    }

    this.setResponseLoadError({
      status: productsResponse.status,
      error: productsResponse.error,
    });
    this.setIsLoading(false);
  };

  overrideConfigFromResponse = (activeProductsMap: {
    [key in eProductType]?: ProductListingDTO;
  }) => {
    const customFieldConfigAllProducts: any = {};
    for (const [productKey, product] of Object.entries(activeProductsMap)) {
      const labelNameList = product?.LabelNames?.LabelNames;

      if (labelNameList) {
        const customGeneralFieldOverridden: CustomFieldConfig = {
          ...customFields[productKey as eProductType],
        };
        for (const [key, value] of Object.entries(
          customGeneralFieldOverridden as Record<
            string,
            CustomFieldAttribute<ECustomColNameMap<eProductType>>
          >
        )) {
          const fieldName = value?.FieldName ?? [];
          const defaultValue = value?.DefaultValue;
          const matchedCustomField = labelNameList.find(
            (labelName: CCLabelNameDTO) =>
              fieldName.includes(
                labelName.ColumnName as ECustomColNameMap<eProductType>
              )
          );
          const labelName = matchedCustomField?.LabelName;

          if (matchedCustomField && defaultValue) {
            customGeneralFieldOverridden[key as keyof CustomFieldConfig] = {
              CustomValue: labelName?.startsWith("&")
                ? labelName.substring(1)
                : labelName,
              DefaultValue: defaultValue,
              FieldName: fieldName as any,
            };
          }
        }

        customFieldConfigAllProducts[productKey as eProductType] =
          customGeneralFieldOverridden;
      }
    }
    this.setCustomFieldConfigAllProducts(customFieldConfigAllProducts);
  };
}

export const commonProductStore = new CommonProductsStore();
const commonProductStoreContext = createContext(commonProductStore);
export const useCommonProductStore = () => {
  return useContext(commonProductStoreContext);
};
