import { EWatchStatusLoading } from "@common/stores/route/model";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

type TDataRoute = Partial<Record<string, any>>;
class RouteDataStore {
  private _dataRoutes: Partial<Record<string, TDataRoute>> = {};
  private _route: string = window.location.pathname;
  private _loadingObject: Partial<Record<EWatchStatusLoading, boolean>> = {};

  constructor() {
    makeAutoObservable(this);
  }

  isLoadingOf = (name: EWatchStatusLoading): boolean => {
    return !!this._loadingObject[name];
  };
  isLoadingOfList = (listName: EWatchStatusLoading[]): boolean => {
    return listName.some((name) => this._loadingObject[name]);
  };
  get isLoadingPage(): boolean {
    return Object.values(this._loadingObject).some((value) => value === true);
  }
  setLoadingList = (name: EWatchStatusLoading, status: boolean) => {
    runInAction(() => {
      this._loadingObject[name] = status;
    });
  };
  clearLoadingList = () => {
    runInAction(() => {
      this._loadingObject = {};
    });
  };

  private setCurrentRouteData = (
    route: string,
    data: Partial<Record<string, any>>
  ) => {
    runInAction(() => {
      this._dataRoutes[route] = data;
    });
  };
  get dataRoute() {
    return toJS(this._dataRoutes[this._route]) as TDataRoute;
  }
  setDataRoute = (dataRoute: TDataRoute) => {
    const prevDataRoute = this._dataRoutes[this._route];
    const newDataRoute: TDataRoute = prevDataRoute
      ? { ...prevDataRoute, ...dataRoute }
      : dataRoute;
    this.setCurrentRouteData(this._route, newDataRoute);
  };

  //#region Reset Data Route ========/
  clearCurrentRouteData = () => {
    runInAction(() => {
      this.setCurrentRouteData(this._route, {});
      this.clearLoadingList();
    });
  };
  //#endregion Reset Data Route =====/
}
export const routeDataStoreInstance = new RouteDataStore();
const routeDataStoreContext = createContext(routeDataStoreInstance);
export const useRouteDataStore = () => {
  return useContext(routeDataStoreContext);
};
